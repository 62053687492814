import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../../shared/data.service';
import {
  RiskOverallEntry,
  StackedBarDataModel
} from '../../models/stacked-bar-data.model';
import { DatePipe } from '@angular/common';
import { DxChartComponent, DxRangeSelectorComponent } from 'devextreme-angular';
import { ChartType, RiskOverAllScale, RiskOverAllScalePeriod } from './risk-overall-chart.model'
import { Subscription } from 'rxjs';

@Component({
  selector: 'cmt-risk-overall-chart',
  templateUrl: './risk-overall-chart.component.html',
  styleUrls: ['./risk-overall-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RiskOverallChartComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('stackBarChart') stackBarChart: DxChartComponent;

  @ViewChild('rangeSelector', { static: false }) rangeSelector: DxRangeSelectorComponent;

  public periodOptions = ['days', 'weeks', 'months', 'years'];

  public defaultPeriod = "years";

  public chartData: StackedBarDataModel<RiskOverallEntry>;

  public datesToPick = [];

  public dates = [];

  private today = new Date();

  private subscriptions: Subscription[] = [];

  constructor(public dataService: DataService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.initChart(RiskOverAllScalePeriod.YEARS, this.prepareChartRequest(RiskOverAllScalePeriod.YEARS));
    this.datesToPick.push(new Date('2008').getFullYear(), new Date('2022').getFullYear())
  }

  public refreshChart(e) {
    this.datesToPick = [];
    this.dates = [];
    switch (e.value) {
      case RiskOverAllScalePeriod.DAYS:
        this.dates = [new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate() - 6
        ),
        new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate()
        )];
        this.rangeSelector.instance.option('scale', { startValue: this.datesToPick[0], endValue: this.datesToPick[1], minorTickInterval : 'day' });
        this.initDates();
        this.initChart(RiskOverAllScalePeriod.DAYS, this.prepareChartRequest(RiskOverAllScalePeriod.DAYS));
        break;
      case RiskOverAllScalePeriod.WEEKS:
        this.initChart(RiskOverAllScalePeriod.WEEKS, this.prepareChartRequest(RiskOverAllScalePeriod.WEEKS));
        this.datesToPick.push(0, 51);
        this.rangeSelector.instance.option('scale', { startValue: this.datesToPick[0], endValue: this.datesToPick[1], minorTickInterval : 'week'  });
        break;
      case RiskOverAllScalePeriod.MONTHS:
        this.initChart(RiskOverAllScalePeriod.MONTHS, this.prepareChartRequest(RiskOverAllScalePeriod.MONTHS));
        this.datesToPick.push(new Date('0').getMonth(), new Date('12').getMonth());
        this.rangeSelector.instance.option('scale', { startValue: this.datesToPick[0], endValue: this.datesToPick[1], minorTickInterval : 'month'  });
        break;
      case RiskOverAllScalePeriod.YEARS:
        this.initChart(RiskOverAllScalePeriod.YEARS, this.prepareChartRequest(RiskOverAllScalePeriod.YEARS));
        const startDate = new Date('2008');
        const endDate = new Date('2022');
        this.datesToPick.push(startDate.getFullYear(), endDate.getFullYear());
        this.rangeSelector.instance.option('scale', { startValue: this.datesToPick[0], endValue: this.datesToPick[1], minorTickInterval : 'year'  });
        break;
    }
  }

  private prepareChartRequest(period: RiskOverAllScalePeriod) {
    if (period === RiskOverAllScalePeriod.DAYS) {
      const daysData: RiskOverAllScale = {
        'start': this.datePipe.transform(this.dates[1], 'yyyy-MM-dd'),
        'end': this.datePipe.transform(this.dates[0], 'yyyy-MM-dd')
      }
      return daysData;
    } else if (period === RiskOverAllScalePeriod.WEEKS) {
      const weeksData: RiskOverAllScale = {
        'startYear': '2022',
        'startWeek': '1',
        'endWeek': '51',
        'endYear': '2022',
      }
      return weeksData;
    } else if (period === RiskOverAllScalePeriod.MONTHS) {
      const monthData: RiskOverAllScale = {
        'startYear': '2021',
        'endYear': '2022',
        'startMonth': '1',
        'endMonth': '12',
        'startWeek': '1',
        'endWeek': '30'
      }
      return monthData;
    } else {
      const yearData: RiskOverAllScale = {
        'startYear': '2008',
        'endYear': '2022'
      }
      return yearData;
    }
  }

  ngAfterViewInit() {
    if (!this.dataService.appService.currentWidth) {
      this.dataService.appService.currentWidth = document.getElementsByClassName('container')[0].clientWidth;
    }
    this.setRiskoverAllSize(this.dataService.appService.isDrawerOpened);
    this.subscriptions.push(this.dataService.appService.onMenuToggleClick.subscribe((isOpened) => {
      this.dataService.appService.isDrawerOpened = isOpened;
      this.setRiskoverAllSize(isOpened);
    }));
  }

  private setRiskoverAllSize(isOpened: boolean) {
    if (isOpened) {
      this.stackBarChart.instance.option('size', { width: this.dataService.appService.currentWidth - 280 });
      this.rangeSelector.instance.option('size', { width: this.dataService.appService.currentWidth - 280 });
    } else {
      this.stackBarChart.instance.option('size', { width: this.dataService.appService.currentWidth - 100 });
      this.rangeSelector.instance.option('size', { width: this.dataService.appService.currentWidth - 100 });
    }
  }

  private initDates(): void {
    for (let i = 0; i < 7; i++) {
      this.datesToPick.push(
        new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate() - i
        )
      );
    }
  }
  public changeChartScale(e,period) {
    this.dates = [];
    this.dates.push(e.value[0], e.value[1]);
    if (period === RiskOverAllScalePeriod.DAYS) {
      const daysData: RiskOverAllScale = {
        'start': this.datePipe.transform(this.dates[1], 'yyyy-MM-dd'),
        'end': this.datePipe.transform(this.dates[0], 'yyyy-MM-dd')
      }
      this.dates  = [
        new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate() - 6
        ),
        new Date(
          this.today.getFullYear(),
          this.today.getMonth(),
          this.today.getDate()
        ),
      ];
      this.initDates();
      this.initChart(RiskOverAllScalePeriod.DAYS, daysData);

    } else if (period === RiskOverAllScalePeriod.WEEKS) {
      const weeksData: RiskOverAllScale = {
        'startYear': '2022',
        'startWeek': e.value[0],
        'endWeek': e.value[1],
        'endYear': '2022',
      }
      this.initChart(RiskOverAllScalePeriod.WEEKS, weeksData);
    } else if (period === RiskOverAllScalePeriod.MONTHS) {
      const monthData: RiskOverAllScale = {
        'startYear': '2021',
        'endYear': '2022',
        'startMonth': e.value[0],
        'endMonth': e.value[1],
        'startWeek': '1',
        'endWeek': '30'
      }
      this.initChart(RiskOverAllScalePeriod.MONTHS, monthData);

    } else {
    const yearData: RiskOverAllScale = {
      'startYear': e.value[0],
      'endYear': e.value[1]
    }
    this.initChart(RiskOverAllScalePeriod.YEARS, yearData);
    }
    
  }

  public initChart(period: RiskOverAllScalePeriod, intreval: RiskOverAllScale): void {
    this.subscriptions.push(this.dataService.getStackedBarChart<RiskOverallEntry>(period, 'riskOverallOverTime', intreval).subscribe(data => {
      this.chartData = data;
    }, error => {
      console.error(error);
    }, () => {
      this.cdr.markForCheck();
    }));
  }

  public customizeTooltip = (arg): { text: string } => {
    switch (this.stackBarChart.commonSeriesSettings.type) {
      case ChartType.AREA:
        return {
          text: arg.valueText
        };
      default:
        return {
          text: arg.percentText
        };
    }
  };

  labelForDate = (date) => this.datePipe.transform(date, 'yyyy-MM-dd');

  ngOnDestroy(): void {
    this.subscriptions.map((subscription) => subscription.unsubscribe());
  }

}