import { MeasureRecommendation, ResponsiblePlan } from "@wissenswerft/organizational-structure";

export class ResponsiblePlanViewModel {
    private responsiblePlan: ResponsiblePlan;
    get plannedDays(): number { return this.responsiblePlan.plannedDays; }
    get remainingDays(): number { return this.responsiblePlan.remainingDays; }
    get responsible(): number { return this.responsiblePlan.responsible.id; }
    get measure(): MeasureRecommendation { return this.responsiblePlan.measure; }

    set plannedDays(plannedDays: number) { this.responsiblePlan.plannedDays = plannedDays }
    set remainingDays(remainingDays: number) { this.responsiblePlan.remainingDays = remainingDays }
    set responsible(responsible: number) { this.responsiblePlan.responsible.id = responsible; }
    set measure(measure: MeasureRecommendation) { this.responsiblePlan.measure = measure; }

    constructor(data: ResponsiblePlan) {
        this.responsiblePlan = data;
    }
}

export const VISIBLE_PROPERTIES = [
    'plannedDays', 'remainingDays', 'responsible', 'measure'
];
