import { EventEmitter, Injectable, Output } from '@angular/core';
import { AccessibleScopes } from '@wissenswerft/core/authentication';
import { Subject } from 'rxjs';
import { HomeMode } from './core/models/home.model';
import { IToast } from '@wissenswerft/ww-library';
import { AccessibleScopesViewModel } from '@wissenswerft/organizational-structure';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public homeMode: HomeMode = HomeMode.company;
  private notificationSubject: Subject<IToast> = new Subject();
  public notification$ = this.notificationSubject.asObservable();
  private accessibleScopes: AccessibleScopesViewModel;
  public showMeasureIcon: boolean = false;
  public showRiskIcon: boolean = false;
  public isDrawerOpened = true;
  public currentWidth : number;
  @Output() selectItem = new EventEmitter();
  @Output() onMenuToggleClick = new EventEmitter();


  constructor() { }

  public getHomeMode(): HomeMode {
    return this.homeMode;
  }

  public setHomeMode(homeMode: HomeMode): void {
    this.homeMode = homeMode;
  }

  public callNotification(notification : IToast): void {
    this.notificationSubject.next(notification);
  }

  public getAccessibleScopes(scopeKey, accessibleScopes?: AccessibleScopes[]) {
    if (!this.accessibleScopes) {
      this.accessibleScopes = new AccessibleScopesViewModel(accessibleScopes);
    }
    return this.accessibleScopes.scopeByName[scopeKey];
  }

  public selectTreeItem = (selectedNode : number) => { this.selectItem.emit(selectedNode); }

}
