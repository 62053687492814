import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataDefinitionViewModel, SharedDataService, SubModule } from '@wissenswerft/organizational-structure';
import { TypeKey } from '@wissenswerft/core/data';
import { DxFormComponent, DxPopupComponent, DxSelectBoxComponent, DxTextBoxComponent, DxValidatorComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { PersistenceService } from 'libs/core/data/src/lib/persistence.service';
import { DataService } from '../../../shared/data.service';
import { GridComponent } from '@wissenswerft/ww-library';
import { VISIBLE_PROPERTIES } from '../../view-models/scope.view-model';
import { ScopeService } from './scope.service';
import { ScopeViewModel } from '../../view-models/scope.view-model'
import { Observable, Subscription } from 'rxjs';
import { WindowLayoutComponent } from '@wissenswerft/ww-library';
import { ToastType } from '@wissenswerft/ww-library';

@Component({
  selector: 'scope',
  templateUrl: './scope.component.html',
  styleUrls: ['./scope.component.scss']
})
export class ScopeComponent implements OnInit, OnDestroy {
  @ViewChild('scopeGrid') scopeGrid: GridComponent;
  @ViewChild('windowLayout') windowLayout: WindowLayoutComponent;
  @ViewChild('createScopePopup') createScopePopup: DxPopupComponent;
  @ViewChild('ident') dxIdentTextBox: DxTextBoxComponent;
  @ViewChild('label') dxLabelTextBox: DxTextBoxComponent;
  @ViewChild('module') dxModuleSelectBox: DxSelectBoxComponent;
  @ViewChild('form') form: DxFormComponent;

  public scope: SubModule;
  public title: string;
  public scopes: ScopeViewModel[];
  private subscriptions: Subscription[] = [];
  public columnsHeader: Column[] = [];

  public createButtonOptions = {
    text: this.dataService.res('Cmt-CreateRisk-Create'),
    useSubmitBehavior: true
  };

  public cancelButtonOptions = {
    text: this.dataService.res('Cmt-CreateRisk-Cancel'),
    onClick: () => this.clearWindow()
  };

  constructor(private sharedDataService: SharedDataService,
    private persistenceService: PersistenceService,
    public dataService: DataService,
    public scopeService: ScopeService) { }


  ngOnInit(): void {
    this.scope = new SubModule(null);
    this.scopeService.prepareModules();
    if (this.sharedDataService.definitionsVM[TypeKey.subModule]) {
      this.title = this.sharedDataService.definitionsVM[TypeKey.subModule].namePlural;
    }
    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<SubModule[]>(TypeKey.subModule).subscribe(data => {
      const definitions = data[0];
      this.sharedDataService.definitionsVM[TypeKey.subModule] = definitions;
      this.title = definitions.namePlural;
      const scopes = data[1];
      this.scopes = [];
      const scopeDefinitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
      this.sharedDataService.definitionsVM[TypeKey.subModule].scopeDefinitionVM = scopeDefinitionVM;
      const properties = scopeDefinitionVM.properties;
      for (const key in properties) {
        if (properties[key]) {
          const property = properties[key];
          this.columnsHeader.push({
            dataField: property.key,
            caption: property.label,
            visible: property?.visible,
            dataType: this.dataService.getDataType(property.type),
            lookup: (this.getLookupColumn(property.key)) ? {
              dataSource: this.getLookupColumn(property.key).dataSource,
              displayExpr: this.getLookupColumn(property.key).displayExpr,
              valueExpr: this.getLookupColumn(property.key).valueExpr
            } : undefined
          });
        }
      }
      scopes.forEach((scope: SubModule) => {
        const scopeViewModel = new ScopeViewModel(scope);
        this.scopes.push(scopeViewModel);
      });
    }));

    this.subscriptions.push(this.sharedDataService.updateGridData$.subscribe((scope: ScopeViewModel) => {
      this.scopes.push(scope);
    }));
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => { subscription.unsubscribe(); });
  }

  public removeRow(event): void {
    const scopeId = event.data.id || event.data.scope.id;
    this.persistenceService.addObjectForDelete(scopeId).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Objekt gelöscht', type: ToastType.INFO });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
  }

  public rowUpdated(event): void {
    const scopeId = event.data.id || event.data.scope.id;
    const scope: ScopeViewModel = new ScopeViewModel(event.data);
    const query = this.scopeService.prepareScopePersistObject(scope);
    const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.subModule].scopeDefinitionVM.multilingualProperties;
    const listProperties = this.sharedDataService.definitionsVM[TypeKey.subModule].scopeDefinitionVM.listProperties;
    const object = this.sharedDataService.createPersistObject(query, multilingualProperties, listProperties);
    this.persistenceService.addObjectForUpdate(scopeId, object).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
  }

  private getLookupColumn(key: string) {
    switch (key) {
      case 'module':
        return { valueExpr: 'id', displayExpr: 'ident', dataSource: this.scopeService.modules };
    }
  }

  public openScopeDialog(): void {
    this.form.instance.resetValues();
    this.createScopePopup.instance.show();
  }

  public selectModule(event): void {
    this.scope.module = event.itemData.id;
  }

  public createScope(event: Event): void {
    event.preventDefault();
    this.persistObject().subscribe((data: ScopeViewModel) => {
      this.sharedDataService.updateGridData(data);
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
      this.clearWindow();
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
    this.scope = new SubModule(null);
    this.form.instance.resetValues();
  }

  private persistObject(): Observable<any> {
    const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.subModule].scopeDefinitionVM.multilingualProperties;
    const listProperties = this.sharedDataService.definitionsVM[TypeKey.subModule].scopeDefinitionVM.listProperties;
    const query = this.sharedDataService.createPersistObject(this.scope, multilingualProperties, listProperties);
    return this.persistenceService.addObjectForInsert(TypeKey.subModule, query);
  }

  public clearWindow(): void {
    this.createScopePopup.instance.hide();
    this.form.instance.resetValues();
  }
}


