import { ChangeDetectorRef, Injectable } from '@angular/core';
import { ResourceManager } from './resource-manager';
import { loadMessages, locale } from 'devextreme/localization';
import deMessages from "devextreme/localization/messages/de.json";
import enMessages from "devextreme/localization/messages/en.json";
import { NavigationMenu } from '@wissenswerft/organizational-structure';
@Injectable({
    providedIn: 'root'
})

export class ResourceManagerService {
    public isDefaultLanguageDe = true;

    public _localiseItemsByKey(items: NavigationMenu[], ressourceName: string) {
        const res = ResourceManager.getResources(ressourceName);
        const intervalId = setInterval(() => {
            items.map((menu: NavigationMenu) => {
                menu.text = res(menu.component);
                if (menu?.items) {
                    this._localiseItemsByKey(menu.items, ressourceName)
                    clearInterval(intervalId);
                }
            });
        }, 500);
    }

    public changeLanguage(languageIsChanged: boolean, cdr: ChangeDetectorRef) {
        if (languageIsChanged) {
            if (sessionStorage.getItem('fixedCulture') === 'de') {
                sessionStorage.setItem('fixedCulture', 'en');
                loadMessages(enMessages);
                locale('en');
                this.isDefaultLanguageDe = false;

            } else {
                sessionStorage.setItem('fixedCulture', 'de');
                loadMessages(deMessages);
                locale('de');
                this.isDefaultLanguageDe = true;
            }
        } else {
            if (!sessionStorage.getItem('fixedCulture')) {
                sessionStorage.setItem('fixedCulture', 'de');
                loadMessages(deMessages);
                locale('de');
                this.isDefaultLanguageDe = true;
            } else {
                if (sessionStorage.getItem('fixedCulture') === 'de') {
                    loadMessages(deMessages);
                    locale('de');
                    this.isDefaultLanguageDe = true;
                } else {
                    loadMessages(enMessages);
                    locale('en');
                    this.isDefaultLanguageDe = false;
                }
            }
        }
        cdr.detectChanges();
    }
}