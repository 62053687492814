import { Injectable } from '@angular/core';

import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';

import { RiskAssessment } from '../core/models/risk-assesment.model';

import { Measure } from '../core/models/measure.model';

import { SpaceOneCoreDataService, TypeKey } from '@wissenswerft/core/data';

import { RiskAssesmentMeasure } from '../core/models/customer-space.model';

import { concatMap, map } from 'rxjs/operators';

import { ClickOrigin } from '../core/models/home.model';

import { AppService } from '../app.service';

import { StackedBarDataModel } from '../core/models/stacked-bar-data.model';

import { ProfileInfo } from 'libs/core/authentication/src/lib/models/profile-info.model';

import { ResourceManager } from "../../../../../libs/core/resources/src/lib/resource-manager";

import { DataDefinitionViewModel } from '@wissenswerft/organizational-structure';

export interface RiskDataAndDefinition {
  riskAssessment: RiskAssessment;
  riskDefinition: DataDefinitionViewModel;
}
@Injectable()
export class DataService {

  private riskAssessmentDetailSubject = new BehaviorSubject<RiskDataAndDefinition>(null);

  private measureDetailSubject = new BehaviorSubject<Measure>(null);

  public clickOrigin: ClickOrigin;

  public cachedResponsiblesResponse: ProfileInfo[];

  public cachedProfileAvatar : string[] = [];

  public res = ResourceManager.getResources("cmt");

  constructor(private dataService: SpaceOneCoreDataService, public appService: AppService) {
  }

  public sendRiskDetail(risk: RiskDataAndDefinition) {
    this.riskAssessmentDetailSubject.next(risk);
  }

  public getRiskDetail(): Observable<RiskDataAndDefinition> {
    return this.riskAssessmentDetailSubject.asObservable();
  }

  public sendMeasureDetail(measureAssessment: Measure) {
    this.measureDetailSubject.next(measureAssessment);
  }

  public getMeasureDetail(): Observable<Measure> {
    return this.measureDetailSubject.asObservable();
  }

  public getStackedBarChart<T>(moment: 'days' | 'weeks' | 'months' | 'years', chartKey: string, dateParams: any): Observable<StackedBarDataModel<T>> {
    return this.dataService.fetchChart(chartKey,
      {
        ...dateParams,
        'range': moment
      }
    );
  }

  public getLanguage() {
    if (sessionStorage.getItem('fixedCulture') === 'de') {
      return 'de'
    } else {
      return 'en'
    }
  }

  public getRiskAssessmentMeasures() {
    const queries = [];
    return this.dataService.getItemsByName<RiskAssesmentMeasure[]>(TypeKey.riskAssessmentMeasure)
      .pipe(concatMap(rAMeasures => {
        queries.push(of(rAMeasures));
        rAMeasures.forEach(rAMeasure => {
          if (rAMeasure?.measure) {
            queries.push(this.dataService.getDynamicContentItemById<Measure>(rAMeasure.measure).pipe(map(measure => {
              rAMeasure.measure = new Measure(measure);
            })));
          }
          if (rAMeasure?.riskIssue) {
            queries.push(this.dataService.getDynamicContentItemById<RiskAssessment>(rAMeasure.riskIssue).pipe(map(risk => {
              rAMeasure.riskIssue = new RiskAssessment(risk);
            })));
          }
        });
        return forkJoin(queries);
      }));
  }

  public getClickOrigin(): ClickOrigin {
    return this.clickOrigin;
  }

  public setClickOrigin(source: ClickOrigin): void {
    this.clickOrigin = source;
  }

  public checkGridGroupByColumns(key: string, groupByColumnIndex) {
    if (localStorage.getItem(key)) {
      const gridState = JSON.parse(localStorage.getItem(key));
      gridState.columns.map(item => {
        if (item.groupIndex) {
          groupByColumnIndex[item.dataField] = item.groupIndex
          return groupByColumnIndex;
        }
      })
    }
  }

  public getDataType(dataType) {
    switch (dataType) {
      case 'TEXT':
        return 'string';
      case 'NUMBER':
        return 'number';
      case 'LOCALDATE':
        return 'date';
      case 'BOOLEAN':
        return 'boolean';
      default:
        return 'string';
    }
  }

  public prepareRiskAssessmentUpdate(data): RiskAssesmentMeasure {
    const query: RiskAssesmentMeasure = new RiskAssesmentMeasure(null);
    query.measure = data.measure?.id;
    query.riskIssue = data.riskIssue?.id;
    query['id'] = data?.id;
    query['type'] = data?.type;
    query['value'] = data?.value;
    return query;
  }

}
