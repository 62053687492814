import { Injectable } from '@angular/core';
import { Module, SubModule } from '@wissenswerft/organizational-structure';
import { SpaceOneCoreDataService, TypeKey } from '@wissenswerft/core/data';
import { ScopeViewModel } from '../../view-models/scope.view-model'
@Injectable({
  providedIn: 'root'
})
export class ScopeService {
  public modules: Module[];

  constructor(private coreDataService: SpaceOneCoreDataService) {
  }

  public prepareScopePersistObject(scope: ScopeViewModel): SubModule {
    const query: SubModule = new SubModule(null);
    query.label = scope.label;
    query.ident = scope.ident.replace(/\s/g, '');
    query.module = scope.module;
    return query;
  }

  public prepareModules(): void {
    this.coreDataService.getItemsByName<Module[]>(TypeKey.module).subscribe({
      next: modules => {
        this.modules = modules;
      }
    });
  }
}
