import { DamageKind, Country, NamedItem, Product, Standard, SubModule, Tag, LegalBasis } from "./organizational-structure.model";

export class RiskIssue extends NamedItem {
    question: string;
    description: string; // RichText
    tags: Tag[];
    subModule: SubModule;
    standard: Standard[];
    damageKinds: DamageKind[];
    products: Product[];
    legalBasis: LegalBasis[];
    countries: Country[];
    clarificationRequired: boolean;
    actionNeeded: boolean;
    notApplicable: boolean;

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}



