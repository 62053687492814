import { Tag } from "@wissenswerft/organizational-structure";
import { Measure, Status } from "../models/measure.model";

export class MeasureViewModel {
    protected measure: Measure;

    get ident(): string { return this.measure.ident; }
    get title(): string { return this.measure.title; }
    get goal(): string { return this.measure.goal; }
    get currentState(): string { return this.measure.currentState; }
    get startDate(): Date {
        if (this.measure?.startDate) {
            return new Date(this.measure?.startDate);
        }
    }
    get endDate(): Date {
        if (this.measure?.endDate) {
            return new Date(this.measure?.endDate);
        }
    }
    get status(): Status { return this.measure.status; }
    get tags(): Tag[] { return this.measure.tags; }
    get measureBenefit() { return this.measure.benefits; }
    get responsible() {
        if (this.measure?.responsible) {
            return this.measure?.responsible.id;
        }
    }


    constructor(data: Measure) {
        this.measure = data;
    }
}

export const VISIBLE_PROPERTIES = [
    'title', 'responsible', 'status', 'startDate', 'endDate', 'measureBenefit'
];

export const MEASURE_TASK_VISIBLE_PROPERTIES = [
    'degreeOfImplementation', 'efficiency', 'label'
];


