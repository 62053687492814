import { MeasureRecommendation, Meta, NamedItem } from '@wissenswerft/organizational-structure';
import { ProfileInfo } from 'libs/core/authentication/src/lib/models/profile-info.model';

export class Measure extends MeasureRecommendation {
    currentState: string; // RichText
    status: Status; // FIXME Is it string or Enum?
    startDate: Date;
    endDate: Date;
    measureDefinitions: MeasureDefinition[];
    orgaTech: OrgaTech;
    prevDect: PrevDectDe;
    responsible: ProfileInfo;
    meta: Meta
    constructor(data) {
        super(data);
        Object.assign(this, data);
        // this.startDate = new Date(data.startDate);
        // this.endDate = new Date(data.endDate);
    }
}

export class MeasureClassification extends NamedItem {

}

export class MeasureDefinition extends NamedItem {
    constructor(data){
        super();
        Object.assign(this, data);
    }
}
export class MeasureTask extends NamedItem {
    notes: string; //RichText
    degreeOfImplementation: number;
    efficiency: number;
    measure: MeasureRecommendation;
    constructor(data){
        super();
        Object.assign(this, data);
    }
}

export enum Status {
    open = 'Offen',
    inprogress = 'In Bearbeitung',
    done = 'Erledigt'
}

export enum OrgaTech {
    ORGANIZATIONAL = 'Organizational',
    TECHNICAL = 'Technical'
}
export enum PrevDectEng {
    PREVENTIVE = 'Preventive',
    DETECTIVE = 'Detective'
}
export enum PrevDectDe {
    PREVENTIVE = 'Präventiv',
    DETECTIVE = 'Aufdeckend'
}