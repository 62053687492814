


import { EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ConvertHelper, SpaceOneCoreDataService, PersistenceService, TypeKey } from '@wissenswerft/core/data';
import { Observable, Subscription } from 'rxjs';
import { MeasureBenefit, SharedDataService } from '@wissenswerft/organizational-structure';
import { Measure, MeasureDefinition, OrgaTech, PrevDectEng, PrevDectDe, Status } from '../../../models/measure.model';
import { DataService } from 'apps/cmt/src/app/shared/data.service';
import { ProfileInfo } from '@wissenswerft/core/authentication';
import { LabelMode, StylingMode, ToastType, ValidationStatus } from '@wissenswerft/ww-library';
import { DxDateBoxComponent, DxFormComponent, DxPopupComponent, DxSelectBoxComponent, DxTagBoxComponent, DxTextAreaComponent, DxTextBoxComponent, DxValidatorComponent } from 'devextreme-angular';
import { ResourceManager } from '@wissenswerft/core/resources';
import DataSource from "devextreme/data/data_source";
import { AppService } from 'apps/cmt/src/app/app.service';
import { ResourceManagerService } from '@wissenswerft/core/resources';
@Component({
  selector: 'measure-create',
  templateUrl: './measure-create.component.html',
  styleUrls: ['./measure-create.component.scss']
})
export class MeasureCreateComponent implements OnInit, OnDestroy {
  @ViewChild('form') form: DxFormComponent;
  @ViewChild('currentStatus') currentStatus: DxSelectBoxComponent;
  @Input() propertiesDefinitions;
  @Output() closePopup: EventEmitter<any> = new EventEmitter();
  @Input() createMeasurePopup: DxPopupComponent;

  public createButtonOptions = {
    text: this.dataService.res('Cmt-CreateMesure-Create'),
    useSubmitBehavior: true
  };

  public cancelButtonOption = {
    text: this.dataService.res('Cmt-CreateMesure-Cancel'),
    onClick: () => this.cancel()
  };

  public labelMode = LabelMode.Floating;
  public stylingMode = StylingMode.Outlined;
  public measureBenefits: Observable<MeasureBenefit[]>;
  public measureDefinitions: Observable<MeasureDefinition[]>;
  public measure: Measure;
  public orgaTech = ConvertHelper.ToArray(OrgaTech);
  public prevDectDe = ConvertHelper.ToArray(PrevDectDe);
  public prevDectEng = ConvertHelper.ToArray(PrevDectEng);
  public prevDect = []
  public responsibles: Observable<ProfileInfo[]>;
  private subscriptions: Subscription[] = [];
  public selectBoxData =
    [
      { id: 1, name: "Verbesserungsansatze identifizieren" },
      { id: 2, name: "Masbnahmenpakete detaillieren/verabschieden" },
      { id: 3, name: "Voraussetzungen zur Umsetzung erfullt" },
      { id: 4, name: "Mabnahmenpaketeumgesetzt" },
      { id: 5, name: "Ergebniswirksamkeit(Guv,Bilanz)" }
    ]

  constructor(private resourceManagerService: ResourceManagerService, private coreDataService: SpaceOneCoreDataService, public appService: AppService, public dataService: DataService, private sharedDataService: SharedDataService, private persistenceService: PersistenceService) { }

  ngOnInit(): void {
    if (this.resourceManagerService.isDefaultLanguageDe) {
      this.prevDect = this.prevDectDe
    }
    else { this.prevDect = this.prevDectEng }
    this.measure = new Measure(null);
    this.measureBenefits = this.coreDataService.getItemsByName(TypeKey.measureBenefit);
    this.measureDefinitions = this.coreDataService.getItemsByName(TypeKey.measureDefinition);
    this.responsibles = this.coreDataService.getAllAccounts();
  }

  public createMeasure(event: Event): void {
    event.preventDefault();
    this.measure.status = Status.open;
    this.subscriptions.push(this.persistObject().subscribe((data: Measure) => {
      console.log(data);

      this.sharedDataService.updateGridData(data);
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
      this.createMeasurePopup.instance.hide();


    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
    this.measure = new Measure(null);
    this.form.instance.resetValues();
  }

  public cancel(): void {
    this.createMeasurePopup.instance.hide();


  }

  public persistObject(): Observable<any> {
    const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.measure].measureDefinitionVM.multilingualProperties;
    const listProperties = this.sharedDataService.definitionsVM[TypeKey.measure].measureDefinitionVM.listProperties;
    const query = this.sharedDataService.createPersistObject(this.measure, multilingualProperties, listProperties);
    return this.persistenceService.addObjectForInsert(TypeKey.measure, query);
  }

  public getResponsibleFullName(responsible: ProfileInfo) {
    if (responsible) {
      return responsible.lastname + ', ' + responsible.firstname;
    }
  }


  public setResponsible(e) {
    return e ? e.id : null;
  }

  public onCustomItemCreating(e) {
    const nextId = this.selectBoxData.length + 1
    e.customItem = { id: nextId, name: e.text };
    this.selectBoxData.push(e.customItem)
    this.currentStatus.instance._refresh();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
