import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TypeKey } from '@wissenswerft/core/data';
import { NamedItemComponent } from '@wissenswerft/organizational-structure';
import { Subscription } from 'rxjs';
import { DataService } from '../../../shared/data.service';

@Component({
  selector: 'measure-type',
  templateUrl: './measure-type.component.html',
  styleUrls: ['./measure-type.component.scss']
})
export class MeasureTypeComponent implements AfterViewInit, OnDestroy {

  @ViewChild('namedItem') namedItem: NamedItemComponent;

  public typeKey: TypeKey = TypeKey.measureType;

  private subscriptions : Subscription[] = [];

  constructor(private dataService: DataService) { }

  ngAfterViewInit(): void {
    this.namedItem.showNotification.subscribe(message => {
      this.dataService.appService.callNotification(message);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

}
