import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from 'libs/core/authentication/src/lib/services/token.service';
import { ConfigService } from 'libs/core/configuration/src/lib/config.service';
import { GeoDsWebApiServiceURLs } from 'libs/core/configuration/src/lib/models/config.model';
import { Observable, Subject } from 'rxjs';
import { ObjectReadInfoModel, Query, QueryColumn, QueryColumnSortOrder, QueryObjectsModel, QueryParameter, ReadListDataSourceModel } from '../models/query.model';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private readObjectsDataServiceUri: string;
  private readListDataSourceUri: string;
  private readCurrentProfileUri: string;
  private objectTypeInfoUri: string;

  constructor(private http: HttpClient, private configService: ConfigService, private tokenService: TokenService) {
    const serviceUrls: GeoDsWebApiServiceURLs = <GeoDsWebApiServiceURLs>this.configService.configuration.WebApi.ServiceURLs;
    this.readObjectsDataServiceUri = serviceUrls.ReadObjects;
    this.readListDataSourceUri = serviceUrls.ReadListDataSource;
    this.objectTypeInfoUri = serviceUrls.ObjectTypeInfo;
    this.readCurrentProfileUri = serviceUrls.Account_ReadCurrentProfile;
  }

  public executeReadObjectsQuery(query: QueryObjectsModel): Observable<any> {
    return this.executeWebApiPOSTMethod(this.readObjectsDataServiceUri, query);
  }

  public executeReadListDataSource(query: ReadListDataSourceModel): Observable<any> {
    return this.executeWebApiPOSTMethod(this.readListDataSourceUri, query);
  }

  public executeReadObjectTypeInfo(query: ObjectReadInfoModel): Observable<any> {
    return this.executeWebApiPOSTMethod(this.objectTypeInfoUri, query);
  }

  public executeWebApiPOSTMethod(url: string, body: any): Observable<ArrayBuffer> {
    const options: any = {
      observe: 'body'
    };
    return this.http.post(url, body, options);
  }

  public getCurrentProfile(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.tokenService.getToken()
    });
    return this.http.post(this.readCurrentProfileUri, { OutputMode: 'Json' }, { headers: headers });
  }

  public createQueryColumn(oPath: string, xPath?: string, sortOrder?: QueryColumnSortOrder, format?: string): QueryColumn {
    const column: QueryColumn = new QueryColumn();
    column.OPath = oPath;
    column.SortOrder = sortOrder;
    column.Name = xPath ? xPath : oPath;
    if (format) { column.Format = format }
    return column;
  }

  public createSimpleQuery(
    objectSpace: string,
    objectType: string,
    oPath: string,
    columns?: QueryColumn[],
    parameters?: QueryParameter[]
  ): QueryObjectsModel {
    const queryDocument: QueryObjectsModel = new QueryObjectsModel();
    queryDocument.ObjectSpaceName = objectSpace;
    const mainQuery: Query = new Query();
    mainQuery.ObjectType = objectType;
    mainQuery.OPath = oPath;
    mainQuery.Columns = columns;
    queryDocument.ObjectQueries = [mainQuery];
    queryDocument.Parameters = parameters;
    return queryDocument;
  }

}
