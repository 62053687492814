import { LegalBasis } from "@wissenswerft/organizational-structure";

export class LegalBasisViewModel {
    private legalBasis: LegalBasis;

    get ident() { return this.legalBasis.ident; }
    get label() { return this.legalBasis.label; }
    get description() { return this.legalBasis.description; }
    get link() { return this.legalBasis.link; }
    set ident(data) { this.legalBasis.ident = data; }
    set label(data) { this.legalBasis.label = data; }
    set description(data) { this.legalBasis.description = data; }
    set link(data) { this.legalBasis.link = data; }
    constructor(data: LegalBasis) {
        this.legalBasis = data;
    }
}

export const VISIBLE_PROPERTIES = [
    'ident', 'label', 'description', 'link'
];