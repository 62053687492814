import { Injectable } from '@angular/core';
import { RiskIssue } from '@wissenswerft/organizational-structure';
import { SpaceOneCoreDataService, TypeKey } from '@wissenswerft/core/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Measure } from '../../models/measure.model';
import { RiskAssesmentMeasureModel } from '../../models/risk-assesment-measure.model';
@Injectable({
  providedIn: 'root'
})
export class RiskAssesmentMeasureService {
  private _risksIssue: Observable<RiskIssue[]>;
  public riskIssueById: {}
  private _measures: Observable<Measure[]>;
  public measureById: {}
  constructor(private coreDataService : SpaceOneCoreDataService) {
  }

  public prepareRiskAMeasurePersistObject(riskAssesmentMeasure: RiskAssesmentMeasureModel): RiskAssesmentMeasureModel {
    const query: RiskAssesmentMeasureModel = new RiskAssesmentMeasureModel(null);
    query.value = riskAssesmentMeasure.value;
    query.type = riskAssesmentMeasure.type;
    query.riskIssue = riskAssesmentMeasure.riskIssue;
    query.measure = riskAssesmentMeasure.measure;
    return query;
  }

  public get risksIssue(): Observable<RiskIssue[]> {
    if (!this._risksIssue) {
      this._risksIssue = this.coreDataService.getItemsByName<RiskIssue[]>(TypeKey.riskAssessment).pipe(map(risks => {
        this.riskIssueById = {};
        risks.forEach((risk => {
          risk = new RiskIssue(risk);
          this.riskIssueById[risk.id] = risk;
        }));
        return risks;
      }));
    }
    return this._risksIssue;
  }

  public get measures(): Observable<Measure[]> {
    if (!this._measures) {
      this._measures = this.coreDataService.getItemsByName<Measure[]>(TypeKey.measure).pipe(map(measures => {
        this.measureById = {};
        measures.forEach((measure => {
          measure = new Measure(measure);
          this.measureById[measure.id] = measure;
        }));
        return measures;
      }));
    }
    return this._measures;
  }

}
