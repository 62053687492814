import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WindowLayoutComponent } from './ww-window-layout/windowLayout.component';
import { GridComponent } from './ww-grid/grid.component';
import { MatrixComponent } from './ww-matrix-grid/matrix-grid.component';
import { ToastComponent } from './ww-toast/toast.component';
import { DxButtonModule, DxDataGridModule, DxPivotGridModule, DxTagBoxModule, DxTextBoxModule, DxToastModule, DxValidatorModule } from 'devextreme-angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DashBoardComponent } from './ww-dashboard/ww-dashBoard.component';

@NgModule({
  declarations: [
    ToastComponent,
    MatrixComponent,
    DashBoardComponent,
    GridComponent,
    WindowLayoutComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    DxToastModule,
    DxPivotGridModule,
    DxDataGridModule,
    DxButtonModule,
    DxTextBoxModule,
    DxTagBoxModule,
    DxValidatorModule
  ],
  exports: [
    ToastComponent,
    MatrixComponent,
    GridComponent,
    WindowLayoutComponent,
    DashBoardComponent
  ]
})
export class WwLibraryModule { }
