import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Country, DamageKind, DataDefinition, DataDefinitionViewModel, Module, SharedDataService, Standard, SubModule } from '@wissenswerft/organizational-structure';
import { ProfileInfo, UserService } from '@wissenswerft/core/authentication';
import { ConvertHelper, SpaceOneCoreDataService, PersistenceService, TypeKey } from '@wissenswerft/core/data';
import { Column } from 'devextreme/ui/data_grid';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { RiskAssesmentMeasure } from '../../../models/customer-space.model';
import { Measure } from '../../../models/measure.model';
import { RiskAssessment, AmountOfDamage, Status } from '../../../models/risk-assesment.model';
import { DxButtonComponent, DxPopupComponent } from 'devextreme-angular';
import { DataService } from '../../../../shared/data.service';
import { RiskAssessmentService } from '../risk-assessment.service';
import { VISIBLE_PROPERTIES } from '../../../view-models/measure.view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { GridComponent, LabelMode, StylingMode } from '@wissenswerft/ww-library';
import { ClickOrigin, Source } from '../../../models/home.model';
import { Comment } from 'libs/core/comments/src/lib/models/comment.model';
import { ToastType } from '@wissenswerft/ww-library';
import { MeasureCreateComponent } from '../../measure/measure-create/measure-create.component';

@Component({
  selector: 'cmt-risk-assesment-detail',
  templateUrl: './risk-assesment-detail.component.html',
  styleUrls: ['./risk-assesment-detail.component.scss']
})

export class RiskAssesmentDetailComponent implements OnInit, OnDestroy {
  @ViewChild('statutButton', { static: false }) statutButton: DxButtonComponent;
  @ViewChild('assignMeasureButton', { static: false }) assignMeasureButton: DxButtonComponent;
  @ViewChild('createMeasurePopup') createMeasurePopup: DxPopupComponent;
  @ViewChild('confirmPopup') confirmPopup: DxPopupComponent;
  @ViewChild('massnahmenGrid') massnahmenGrid: GridComponent;
  @ViewChild('createForm') createForm: MeasureCreateComponent;
  @Input() detailsMode: string;

  public labelMode = LabelMode.Floating;
  public stylingMode = StylingMode.Outlined;
  public measures: Measure[];
  public measure: Measure;
  public risk: RiskAssessment;
  public currentRiskIndex: number;
  public toogleTitleVisible: boolean = false;
  public toogleQuestionVisible: boolean = false;
  private subscriptions: Subscription[] = [];
  private destroy$ = new Subject<void>();
  public measureSuggestions: Array<RiskAssesmentMeasure>;
  public measureSuggestionsColumns: Column[] = [
    {
      dataField: 'measure.title', caption: 'Measure'
    }
  ];

  public toggleTitle = (): void => {
    this.toogleTitleVisible = !this.toogleTitleVisible;
  }

  public toogleQuestion = (): void => {
    this.toogleQuestionVisible = !this.toogleQuestionVisible;
  }

  public assignedMeasureColumns: Column[];
  private readonly base64Format: string = "data:image/png;base64,";
  public assignedMeasure: Array<RiskAssesmentMeasure>;
  public module: Module;
  public standards: Observable<Standard[]>;
  public amountOfDamage = ConvertHelper.ToArray(AmountOfDamage);
  public damageKinds: Observable<DamageKind[]>;
  public countries: Observable<Country[]>;
  public riskProperties;
  public allComments: Comment;
  public riskAssesmentComments: Comment[];
  public currentScope: string;
  public selectedModule: Module;
  public subModules: SubModule[];
  public responsibles: ProfileInfo[] = [];
  private assignMeasure: string;
  private assignActivity: string;
  private selectedMeasure: Measure;
  private riskAssessmenMeasureId: number;

  constructor(private coreDataService: SpaceOneCoreDataService,
    public dataService: DataService,
    private sharedDataService: SharedDataService,
    private router: Router,
    private userService: UserService,
    private persistenceService: PersistenceService,
    public riskService: RiskAssessmentService,
    private actRoute: ActivatedRoute) {
    this.riskService.LocalizeEnums();
    if (!this.riskService.risks) {
      this.subscriptions.push(this.sharedDataService.getDefinitionAndData<RiskAssessment[]>(TypeKey.riskAssessment).pipe(takeUntil(this.destroy$)).subscribe((data) => {
        this.riskService.risks = data[1];
      }));
    }
    if (!this.riskService.riskAssessmentDefinitionVM) {
      this.subscriptions.push(this.coreDataService.getDynamicContentItemById<RiskAssessment>(this.actRoute.snapshot.params.id).subscribe((risk => {
        const riskDetail = new RiskAssessment(risk);
        this.coreDataService.getDefinitonByTypeKey<DataDefinition>(TypeKey.riskAssessment).subscribe((def => {
          const riskAssessmentDefinitionVM = new DataDefinitionViewModel(def, VISIBLE_PROPERTIES);
          this.riskService.riskAssessmentDefinitionVM = riskAssessmentDefinitionVM;
          this.dataService.sendRiskDetail({
            riskAssessment: riskDetail,
            riskDefinition: this.riskService.riskAssessmentDefinitionVM
          });
        }))
      })));
    }

    this.subscriptions.push(this.dataService.getRiskDetail().subscribe(data => {
      if (data) {
        this.risk = data.riskAssessment;
        this.currentRiskIndex = this.riskService.risks.findIndex(x => x.id == this.risk.id);
        if (!this.risk?.status) this.risk.status = Status.open;
        if (this.risk.probability) this.risk.probability = this.convertToNumber(this.risk.probability);
        if (this.risk.amountOfDamage) this.risk.amountOfDamage = this.convertToNumber(this.risk.amountOfDamage);
        this.riskProperties = data.riskDefinition.properties;
        this.getModule(this.risk.subModule.module);
      }
    }));
  }

  ngOnInit(): void {
    this.measureSuggestions = [];
    this.assignedMeasure = [];
    this.currentScope = this.coreDataService.getScope();
    this.subscriptions.push(this.riskService.getSelectedModule().subscribe(module => {
      // this.selectedModule = module;
      // this.onSelectModule({id: this.selectedModule.id});
    }));
    this.standards = this.coreDataService.getItemsByName(TypeKey.standard);
    this.damageKinds = this.coreDataService.getItemsByName(TypeKey.damageKind);
    this.countries = this.coreDataService.getItemsByName(TypeKey.country);
    // this.coreDataService.getItemsByName(TypeKey.measure).subscribe((data: Measure[]) => {

    // });
    // if (!this.dataService.definitionsVM[TypeKey.measure]) {
    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<Measure[]>(TypeKey.measure)
      .subscribe((data) => {
        const definitions = data[0];
        this.measures = data[1];
        this.sharedDataService.definitionsVM[TypeKey.measure] = definitions;
        const measureDefinitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
        this.sharedDataService.definitionsVM[TypeKey.measure].measureDefinitionVM = measureDefinitionVM;
        this.assignedMeasureColumns = [
          {
            dataField: 'measure.id', dataType: 'string', caption: 'Measure', minWidth: 50, width: '80%', cellTemplate: "cellTemplate", lookup: {
              dataSource: this.measures,
              displayExpr: 'title',
              valueExpr: 'id'
            }
          },
          {
            dataField: 'value', alignment: 'center', dataType: 'number', width: '20%', caption: 'Riskoreduktion', lookup: {
              dataSource: this.getLookupColumn('Riskoreduktion').dataSource,
              displayExpr: this.getLookupColumn('Riskoreduktion').displayExpr,
              valueExpr: this.getLookupColumn('Riskoreduktion').valueExpr
            },
            format: { type: 'fixedPoint', precision: 0 }
          }
        ];

      }));
    this.prepareRiskAssessmentMeasures();
    this.subscriptions.push(this.sharedDataService.updateGridData$.subscribe((data) => {
      this.measures.push(data);
    }));
    this.subscriptions.push(this.coreDataService.getCommentsByItemId<Comment[]>(this.actRoute.snapshot.params.id).subscribe(data => {
      this.riskAssesmentComments = data;
      this.riskAssesmentComments.forEach(element => {
        if (!this.dataService.cachedProfileAvatar[element.author.id]) {
          this.userService.getAvatarById(element.author.id).subscribe(avatar => {
            const imageBase = this.base64Format + btoa(
              new Uint8Array(avatar)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            element.avatar = imageBase;
            this.dataService.cachedProfileAvatar[element.author.id] = imageBase;
          })
        } else {
          element.avatar = this.dataService.cachedProfileAvatar[element.author.id];
        }
      });
    }));
    this.subscriptions.push(this.coreDataService.getAllAccounts<ProfileInfo[]>().subscribe(responsibles => {
      this.responsibles = responsibles;
    }
    ));
  }

  public linkClick(e) {
    if (e.rowType === 'data' && e.column.dataField === "measure.id") {
      const riskSource: ClickOrigin = new ClickOrigin({ id: this.risk.id, source: Source.RiskDetail, value: true });
      this.dataService.setClickOrigin(riskSource);
      this.dataService.sendMeasureDetail(e.data.measure);
      this.dataService.appService.showMeasureIcon = true;
      this.dataService.appService.showRiskIcon = false;
      this.dataService.appService.selectTreeItem(3);
      this.router.navigate(['measureDetail', e.data.measure.id]);
    }
  }

  public onCellPrepared = (e): void => {
    if (e.column.dataType == 'number') {
      e.cellElement.style.textAlignLast = 'center';
    }
  }

  private getLookupColumn(key: string) {
    switch (key) {
      case 'Riskoreduktion':
        return { valueExpr: 'id', displayExpr: 'value', dataSource: this.riskService.degree };
    }
  }

  public onCustomItemCreating(event) {
    const queries = [];
    this.subscriptions.push(this.dataService.getRiskAssessmentMeasures()
      .subscribe((data) => {
        const riskAssessmentMeasures: RiskAssesmentMeasure[] = data[0] as RiskAssesmentMeasure[];
        riskAssessmentMeasures.forEach((riskAssessmentMeasure => {
          const ram = new RiskAssesmentMeasure(riskAssessmentMeasure);
          if (ram.type === 'suggestion') {
            this.measureSuggestions.push(ram);
          } else {
            if (ram.riskIssue.id === this.risk.id) {
              this.assignedMeasure.push(ram);
            }
          }
        }));
      }));
  }

  public getTagBoxLabel(e) {
    return e?.label || e?.ident;
  }

  public convertToNumber(value): number {
    return parseInt(value);
  }

  public setProbabilityAverage(amountOfDamage: number, probability: number): number {
    const gaugeValue = ((amountOfDamage * probability) * 100) / 49;
    return gaugeValue;
  }

  public ratingChanged(event, kind): void {
    const selectBox = document.getElementById(event.element.id);
    if (!event.selectedItem) {
      selectBox.style.backgroundColor = "white";
  }
    else {
      this.risk[kind] = event.selectedItem.value;
      switch (event.selectedItem.value) {
        case 7:
          selectBox.style.backgroundColor = "red";
          break;
        case 5:
          selectBox.style.backgroundColor = "orange";
          break;
        case 3:
          selectBox.style.backgroundColor = "yellow";
          break;
        case 1:
          selectBox.style.backgroundColor = "lime";
          break;
      }
    }
  }

  public getResponsibleFullName(responsible: ProfileInfo) {
    if (responsible) {
      return responsible.lastname + ', ' + responsible.firstname;
    }
  }

  customizeText(arg: any) {
    return arg.valueText + " %";
  }

  public persistChanges(event) {
    const multilingualProperties = this.riskService.riskAssessmentDefinitionVM.multilingualProperties;
    delete this.risk.meta.updated;
    const query: any = Object.assign({}, this.risk);
    multilingualProperties.forEach(prop => {
      query[prop.key] = { "de": query[prop.key], 'en': query[prop.key] };
    });
    this.subscriptions.push(this.persistenceService.addObjectForUpdate(this.risk.id, query).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: 'Verantwortliches Feld muss ausgefüllt werden', type: ToastType.ERROR });
    }));
  }

  public assignMeasureToRisk(): void {
    if (this.selectedMeasure) {
      const query: RiskAssesmentMeasure = new RiskAssesmentMeasure({
        measure: this.selectedMeasure,
        riskIssue: this.risk,
        type: 'assigned',
        value: 0
      });
      const assignedMeasure: boolean = this.assignedMeasure.some(element => element.measure.id === query.measure.id && element.riskIssue.id === query.riskIssue.id);
      if (assignedMeasure) {
        this.dataService.appService.callNotification({ message: 'Maßnahme ist bereits zugewiesen', type: ToastType.ERROR });
      } else {
        this.subscriptions.push(this.persistenceService.addObjectForInsert(TypeKey.riskAssessmentMeasure, query).subscribe((data) => {
          query.id = data.id;
          this.massnahmenGrid.gridData.push(query);
          this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
        }, error => {
          this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
        }));
      }
    } else {
      this.measure = new Measure(null);
      this.createForm.form.instance.resetValues();
      this.createMeasurePopup.instance.show()
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public openConfirmDialog(id): void {
    this.riskAssessmenMeasureId = id;
    this.confirmPopup.instance.show();
  }

  public onMeasureSelectionChanged(e) {
    this.selectedMeasure = e.selectedItem;
  }

  public changeButtonStatut(): void {
    if (this.statutButton.text === Status.bewertet) { this.statutButton.text = Status.open; this.risk.status = Status.open; }
    else if (this.statutButton.text === Status.open) { this.statutButton.text = Status.planned; this.risk.status = Status.planned; }
    else if (this.statutButton.text === Status.planned) { this.statutButton.text = Status.bewertet; this.risk.status = Status.bewertet; }
  }

  public onResponsibleChange(e): void {
    this.risk.responsible = new ProfileInfo(this.sharedDataService.responsibleById[e.value]);
  }

  public onScopeChange(e) {
    this.risk.subModule = new SubModule(this.riskService.scopeById[e.value]);
  }

  public setResponsible(e) {
    return e ? e.id : undefined;
  }

  public setScope(e) {
    return e ? e.id : null;
  }

  public getUpdatedDateTime() {
    if (this.risk.meta.updated) {
      const updated = new Date(this.risk.meta.updated);
      return updated.toLocaleDateString() + ' ' + updated.toLocaleTimeString();
    }
  }

  public onClosePopup(): void {
    this.createMeasurePopup.instance.hide();
  }

  private openMeasureDetail(measureId: string): void {
    const riskSource: ClickOrigin = new ClickOrigin({ id: this.risk.id, source: Source.RiskDetail, value: true });
    this.dataService.setClickOrigin(riskSource);
    this.router.navigate(['measureDetail', measureId]);
  }

  public abortDelete() {
    this.confirmPopup.instance.hide();
  }

  public removeRow(e): void {
    this.subscriptions.push(this.persistenceService.addObjectForDelete(e.data.id).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Objekt gelöscht', type: ToastType.INFO });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
    this.confirmPopup.instance.hide();
    this.massnahmenGrid.refreshGrid();
  }

  private addPercentSymbol(arg): string {
    return arg.valueText + '%';
  }

  public backToRiskList(): void {
    if (this.dataService.getClickOrigin()?.source == Source.MeasureDetail) {
      this.router.navigate(['measureDetail', this.dataService.getClickOrigin().id]);
      this.dataService.appService.showMeasureIcon = true;
      this.dataService.appService.showRiskIcon = false;
      this.dataService.appService.selectTreeItem(3);
      this.dataService.setClickOrigin({ id: this.risk.id, source: Source.RiskDetail, value: true });
    }
    else {
      this.dataService.appService.showRiskIcon = false;
      this.router.navigateByUrl('risks');
    }
  }

  public getDisplayExpr(obj: SubModule | Module) {
    if (obj) {
      if (obj?.label) {
        return obj.label;
      } else {
        return obj.ident;
      }
    }
  }

  public getValueExpression(object: SubModule | Module) {
    if (object) {
      return object.id;
    }
  }

  public onSelectModule(event): void {
    const id = event?.itemData?.id || event.id;
    this.subscriptions.push(this.coreDataService.getItemsBySpoqlQuery<SubModule[]>('subModule', `property 'module' eq ${id}`).subscribe(data => {
      this.subModules = data;
    }));
  }

  private getModule(moduleId): void {
    this.subscriptions.push(this.coreDataService.getDynamicContentItemById<Module>(moduleId).subscribe((module => {
      this.selectedModule = new Module(module);
      this.onSelectModule(this.selectedModule)
    })));
  }

  public updateRow(event): void {
    const query = this.dataService.prepareRiskAssessmentUpdate(event.data);
    this.subscriptions.push(this.persistenceService.addObjectForUpdate(event.data.id, query).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  public onRiskDetailsValueChanged(key, event) {
    if (this.risk[key]) {
      this.risk[key] = event.value;
    }
  }

  public navigateToRisk = (index: number): void => {
    this.measureSuggestions = [];
    this.currentRiskIndex = this.currentRiskIndex + index;
    const riskDetail = new RiskAssessment(this.riskService.risks[this.currentRiskIndex]);
    this.subscriptions.push(this.coreDataService.getDynamicContentItemById<RiskAssessment>(riskDetail.id).subscribe(risk => {
      this.dataService.sendRiskDetail({
        riskAssessment: risk,
        riskDefinition: this.riskService.riskAssessmentDefinitionVM
      });
    }));
    this.prepareRiskAssessmentMeasures();
    this.router.navigate(['riskAssessmentDetail', this.risk.id]);
    this.subscriptions.push(this.coreDataService.getCommentsByItemId<Comment[]>(this.risk.id).subscribe(data => {
      this.riskAssesmentComments = data;
      this.riskAssesmentComments.forEach(element => {
        element.avatar = this.dataService.cachedProfileAvatar[element.author.id];
      });
    }));
  }

  private prepareRiskAssessmentMeasures = (): void => {
    this.subscriptions.push(this.dataService.getRiskAssessmentMeasures()
      .pipe(map((data) => {
        this.assignedMeasure = [];
        const riskAssessmentMeasures: RiskAssesmentMeasure[] = data[0] as RiskAssesmentMeasure[];
        riskAssessmentMeasures.forEach((riskAssessmentMeasure => {
          const ram = new RiskAssesmentMeasure(riskAssessmentMeasure);
          if (ram.type === 'suggestion') {
            this.measureSuggestions.push(ram);
          }
          else {
            if (ram.riskIssue && ram.riskIssue?.id === this.risk?.id) {
              this.assignedMeasure.push(ram);
            }
          }
        }));
        return riskAssessmentMeasures;
      })).subscribe());
  }

}