import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentsComponent } from './comments/comments.component';
import { DxBoxModule, DxButtonModule, DxListModule, DxScrollViewModule, DxTextAreaModule } from 'devextreme-angular';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    DxBoxModule,
    DxListModule,
    DxTextAreaModule,
    DxButtonModule,
    DxScrollViewModule,
    FlexLayoutModule
  ],
  declarations: [CommentsComponent],
  providers: [],
  exports: [CommentsComponent]
})
export class CoreCommentsModule {}