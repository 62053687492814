import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Damage, SpiderChartAxis, SpiderDataModel } from '../../models/dashboard.model';
import { DataService } from '../../../shared/data.service';
import { DxPolarChartComponent } from 'devextreme-angular';


@Component({
  selector: 'risk-per-module-spider',
  templateUrl: './risk-per-module.component.html',
  styleUrls: ['./risk-per-module.component.scss']
})
export class RiskPerModuleComponent implements OnInit, AfterViewInit {

  @ViewChild('spiderChart') polarChart: DxPolarChartComponent

  @Input('spiderData') spiderData: SpiderDataModel[] = [];

  public spiderChartOptions: SpiderChartAxis[] = [{ name: Damage.LOW, color: '#5CE160', valueField: 'countLow', key: 'Cmt-DamageLow' },
  { name: Damage.MEDIUM, color: '#FFDD33', valueField: 'countMedium', key: 'Cmt-DamageMedium' },
  { name: Damage.HIGH, color: '#FFA233', valueField: 'countHigh', key: 'Cmt-DamageHigh' },
  { name: Damage.VERYHIGH, color: '#FF5733', valueField: 'countVeryHigh', key: 'Cmt-DamageVeryHigh' }
  ];

  constructor(private dataService: DataService) {
  }

  ngOnInit(): void {
  }

  public ngAfterViewInit() {
    const intervalId = setInterval(() => {
      this.spiderChartOptions.forEach(item => {
        item.name = this.dataService.res(item.key)
        clearInterval(intervalId);
        this.polarChart.instance.option('series', this.spiderChartOptions)
      })
    }, 500);

  }
}
