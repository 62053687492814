export enum ChartType {
    BAR = 'bar',
    AREA = 'area',
    BUBBLE = 'bubble',
    STACKBAR = 'stackedbar',
    LINE = 'line',
    RANGEAREA = 'rangearea',
    RANGEBAR = 'rangebar',
    SCATTER = 'scatter',
    SPLINE = 'spline',
    SPLINEAREA = 'splinearea',
    STOCK = 'stock'
}

export interface RiskOverAllScale {
    [key: string]: any;
}

export enum RiskOverAllScalePeriod {
    DAYS = "days",
    WEEKS = "weeks",
    MONTHS = "months",
    YEARS = "years"
}
