import { RiskAssessment } from './risk-assesment.model';
import { Measure } from './measure.model';
import { CatalogLibraryBase } from '@wissenswerft/organizational-structure';

export class RiskAssesmentMeasure extends CatalogLibraryBase {
    value: number;
    riskIssue: RiskAssessment;
    measure: Measure;
    type: 'suggestion' | 'assigned';

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}
