import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { TypeKey } from '@wissenswerft/core/data';
import { NamedItemComponent } from '@wissenswerft/organizational-structure';
import { Subscription } from 'rxjs';
import { DataService } from '../../../shared/data.service';

@Component({
    selector: 'measure-benefit',
    templateUrl: './measure-benefit.component.html',
    styleUrls: ['./measure-benefit.component.scss']
})
export class MeasureBenefitComponent implements AfterViewInit, OnDestroy {

    @ViewChild('namedItem') namedItem: NamedItemComponent;

    public typeKey: TypeKey = TypeKey.measureBenefit;

    private subscriptions : Subscription[] = [];

    constructor(private dataService: DataService) { }

    ngAfterViewInit(): void {
        this.namedItem.showNotification.subscribe(message => {
            this.dataService.appService.callNotification(message);
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.map(subscription => subscription.unsubscribe());
      }
}