import { Injectable } from '@angular/core';
import { RiskAssesmentMeasure } from '@wissenswerft/organizational-structure';
import { Measure, MeasureTask, OrgaTech } from '../../models/measure.model';


@Injectable({
  providedIn: 'root'
})
export class MeasureService {
  public measures : Measure[];
  public orgaTech = [
    { label: OrgaTech.ORGANIZATIONAL, value: OrgaTech.ORGANIZATIONAL },
    { label: OrgaTech.TECHNICAL, value: OrgaTech.TECHNICAL }
  ]

  public degree = [{ id: 0, value: "0%" }, { id: 25, value: "25%" }, { id: 50, value: "50%" }, { id: 75, value: "75%" }, { id: 100, value: "100%" }]

  constructor() { }

  public prepareRiskPersistObject(data, measure): MeasureTask {
    const query: MeasureTask = new MeasureTask(null);
    query.label = data.label;
    query.ident = data.label.replace(/\s/g, '');
    query.measure = measure;
    query.notes = data.notes;
    query.measure = measure;
    query.degreeOfImplementation = data.degreeOfImplementation;
    query.efficiency = data.efficiency;
    return query;
  }

}
