import { Module } from '@wissenswerft/organizational-structure';

export class ModuleViewModel {
    protected module: Module;

    get ident() { return this.module.ident; }
    get label() { return this.module.label; }
    get subModules() { return this.module.submodules; }
    set ident(data) { this.module.ident = data; }
    set label(data) { this.module.label = data; }
    set subModules(data) { this.module.submodules = data; }
    constructor(data: Module) {
        this.module = data;
    }
}

export const VISIBLE_PROPERTIES = [
    'ident', 'label'
];