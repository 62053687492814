import { MeasureRecommendation, RiskIssue } from "@wissenswerft/organizational-structure";
import { RiskAssesmentMeasureType, RiskAssesmentMeasureModel } from "../models/risk-assesment-measure.model";


export class RiskAssesmentMeasureViewModel {
    private riskAssesmentMeasure: RiskAssesmentMeasureModel;
    get value(): number { return this.riskAssesmentMeasure.value; }
    get type(): RiskAssesmentMeasureType { return this.riskAssesmentMeasure.type; }
    get riskIssue(): RiskIssue { return this.riskAssesmentMeasure.riskIssue; }
    get measure(): MeasureRecommendation { return this.riskAssesmentMeasure.measure; }

    set value(value: number) { this.riskAssesmentMeasure.value = value }
    set type(type: RiskAssesmentMeasureType) { this.riskAssesmentMeasure.type = type }
    set riskIssue(riskIssue: RiskIssue) { this.riskAssesmentMeasure.riskIssue = riskIssue; }
    set measure(measure: MeasureRecommendation) {this.riskAssesmentMeasure.measure = measure; }

    constructor(data: RiskAssesmentMeasureModel) {
        this.riskAssesmentMeasure = data;
    }
}

export const VISIBLE_PROPERTIES = [
    'value', 'type', 'riskIssue', 'measure'
];


