import { Injectable } from '@angular/core';
import { Standard } from '@wissenswerft/organizational-structure';
import { StandardViewModel } from '../../view-models/standard.view-model';
@Injectable({
  providedIn: 'root'
})
export class StandardService {

  constructor() { }

  public prepareStandardPersistObject(standard: StandardViewModel): Standard {
    const query: Standard = new Standard();
    query.label = standard.label;
    query.ident = standard.ident.replace(/\s/g, '');
    query.description = standard.description;
    return query;
  }
}