import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TypeKey } from '@wissenswerft/core/data';
import { DataService } from '../../shared/data.service';
import { RiskAssessment } from '../models/risk-assesment.model';
import { FieldArea } from 'libs/ww-library/src/lib/ww-matrix-grid/matrix-grid.model';
import { Damage, DashBoardMeasure, DashBoardRisk, MatrixViewKey, RiskDistribution, SpiderDataModel } from '../models/dashboard.model';
import { MatrixComponent } from '@wissenswerft/ww-library';
import { Subscription } from 'rxjs';
import { RiskAssesmentMeasure } from '../models/customer-space.model';
import { Measure } from '../models/measure.model';
import { Column } from 'devextreme/ui/data_grid';
import { Module, SharedDataService } from '@wissenswerft/organizational-structure';
import { DxPolarChartComponent } from 'devextreme-angular';


@Component({
  selector: 'cmt-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('riskMatrix') riskMatrix: MatrixComponent;

  @ViewChild('spiderChart') spiderChart: DxPolarChartComponent;

  public riskDistributionDataSource: RiskDistribution[] = [];

  private data: RiskDistribution[] = [];

  public dataSource = {};

  private subscriptions: Subscription[] = [];

  public averageRiskAmountOfDamages: number = 0;

  public selectedSwitch: string = (this.dataService.getLanguage() === 'de') ? 'Gesamtübersicht' : 'General overview';

  public measures: DashBoardMeasure[] = [];

  public risks: DashBoardRisk[] = [];

  public spiderChartDataSource = [(this.dataService.getLanguage() === 'de') ? 'Gesamtübersicht' : 'General overview',
  (this.dataService.getLanguage() === 'de') ? 'Schadenshöhe' : 'Damage level',
  (this.dataService.getLanguage() === 'de') ? 'Eintrittswahrscheinlichkeit' : 'Probability of occurrence'
  ];

  public modules: Module[] = [];

  public spiderData = [];

  private overAllSpiderData: SpiderDataModel[] = [];

  private probabilitySpiderData: SpiderDataModel[] = [];

  private damageSpiderData: SpiderDataModel[] = [];

  public riskAssesmentMeasureColumns: Column[] = [
    {
      dataField: 'title', caption: (this.dataService.getLanguage() === 'de') ? 'Maßnahme' : 'Measure', dataType: 'string'
    },
    {
      dataField: 'riskReduction', caption: (this.dataService.getLanguage() === 'de') ? 'Risikoreduktion' : 'Risk reduction', calculateCellValue: this.calculateCellValue, dataType: 'string'
    },
    {
      dataField: 'deadLine', caption: (this.dataService.getLanguage() === 'de') ? 'Enddatum' : 'Final date', dataType: 'date'
    }
  ];

  public riskColumns: Column[] = [
    {
      dataField: 'module', caption: (this.dataService.getLanguage() === 'de') ? 'Modul' : 'Module'
    },
    {
      dataField: 'risk', caption: (this.dataService.getLanguage() === 'de') ? 'Realistische Schadenshöhe' : 'Realistic damage level', lookup: {
        dataSource: [(this.dataService.getLanguage() === 'de') ? 'Niedrig' : 'Low', (this.dataService.getLanguage() === 'de') ? 'Mittel' : 'Medium', (this.dataService.getLanguage() === 'de') ? 'Hoch' : 'High', (this.dataService.getLanguage() === 'de') ? 'Sehr Hoch' : 'Very High']

      }
    }
  ];


  constructor(public sharedDataService: SharedDataService, public dataService: DataService) {
    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<Module[]>(TypeKey.module).subscribe((data) => {
      this.modules = data[1]
      data[1].forEach(element => {
        this.damageSpiderData.push({ arg: element.id, countHigh: 0, countLow: 0, countMedium: 0, countVeryHigh: 0 });
        this.probabilitySpiderData.push({ arg: element.id, countHigh: 0, countLow: 0, countMedium: 0, countVeryHigh: 0 });
        this.overAllSpiderData.push({ arg: element.id, countHigh: 0, countLow: 0, countMedium: 0, countVeryHigh: 0 });
        const dashboardRisk: DashBoardRisk = new DashBoardRisk(element.id, element.label, [], '');
        this.risks.push(dashboardRisk)
      })
    }));
  }
  public size = {
    height: 400,
    width: 100
  }
  ngOnInit(): void {

    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<Measure[]>(TypeKey.measure).subscribe((data) => {
      const measures: Measure[] = data[1];
      measures.forEach(measure => {
        this.measures.push(new DashBoardMeasure(measure.id, measure.title, measure.endDate, [], 0));
      });
    }));
    this.subscriptions.push(this.dataService.getRiskAssessmentMeasures().subscribe(data => {
      const riskAssessmentMeasures: RiskAssesmentMeasure[] = data[0] as RiskAssesmentMeasure[];
      riskAssessmentMeasures.map((riskAssessmentMeasure => {
        this.measures.find(element => {
          if (element.id === riskAssessmentMeasure.measure?.id) {
            element.ram.push(riskAssessmentMeasure.value)
          };
        });
      }));
      let total: number = 0;
      this.measures.map(measure => {
        measure.ram?.map(element => {
          total += element;
        });
        if (measure.ram.length !== 0) {
          measure.riskReduction = total / measure.ram.length;
        }
      })
    }));

    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<RiskAssessment[]>(TypeKey.riskAssessment).subscribe((data) => {
      const riskAssessments = data[1];
      let total: number = 0;
      riskAssessments.forEach(risk => {
        this.risks.find(element => {
          if (element.id === risk.subModule.module) {
            element.damage.push(risk.amountOfDamage)
          }
        })
        if (risk.probability && risk.amountOfDamage) {
          this.fillSpiderChartData(risk.module || risk.subModule.module, this.setProbabilityAverage(risk.amountOfDamage, risk.probability), this.overAllSpiderData, true);
          this.fillSpiderChartData(risk.module || risk.subModule.module, risk.amountOfDamage, this.damageSpiderData);
          this.fillSpiderChartData(risk.module || risk.subModule.module, risk.probability, this.probabilitySpiderData);
          this.data.push(new RiskDistribution(this.setMatrixHeader(risk.probability), this.setMatrixHeader(risk.amountOfDamage), 0));
          const avrgAD: number = (risk.amountOfDamage * 100) / 7;
          total += avrgAD;
        }
      });

      this.overAllSpiderData.map(element => {
        this.modules.find(x => {
          if (x.id == element.arg) element.arg = x.label;
        })
      });

      this.damageSpiderData.map(element => {
        this.modules.find(x => {
          if (x.id == element.arg) element.arg = x.label;
        })
      });

      this.probabilitySpiderData.map(element => {
        this.modules.find(x => {
          if (x.id == element.arg) element.arg = x.label;
        })
      });

      this.spiderData = this.overAllSpiderData;

      this.risks.map(element => {
        let riskTotal: number = 0;
        element.damage.map(damage => {
          const avrgAD: number = (damage * 1);
          riskTotal += avrgAD;
        });
        if (element.damage.length != 0) {
          const riskPerModule: number = riskTotal / element.damage.length;
          element.risk = this.prepareRiskLabel(riskPerModule);
        }
      })
      if (riskAssessments.length != 0) {
        this.averageRiskAmountOfDamages = total / riskAssessments.length;
      }


      this.dataSource = {
        fields: [{
          dataField: 'probability.text',
          width: 50,

          area: FieldArea.ROW,
          sortingMethod: (a, b) => {
            return parseInt(MatrixViewKey[b.value?.replace(' ', '')]) - parseInt(MatrixViewKey[a.value?.replace(' ', '')]);
          }
        }, {
          dataField: 'damage.text',
          width: 50,
          area: FieldArea.COLUMN,
          sortingMethod: (a, b) => {
            return parseInt(MatrixViewKey[a.value?.replace(' ', '')]) - parseInt(MatrixViewKey[b.value?.replace(' ', '')]);
          }
        },
        {
          dataField: 'sum',
          area: FieldArea.DATA
        }],
        store: this.data
      }
    }));

  }

  public calculateCellValue(arg: any) {
    return arg.riskReduction + " %";
  }

  private setProbabilityAverage(amountOfDamage: number, probability: number): number {
    const overAllValue = ((amountOfDamage * probability));
    return overAllValue;
  }

  private prepareRiskLabel = (value: number): string => {
    if (value >= 1 && value < 3) {
      return (this.dataService.getLanguage() === 'de') ? 'Niedrig' : "Low";
    } else if (value >= 3 && value < 5) {
      return (this.dataService.getLanguage() === 'de') ? 'Mittel' : "Medium";
    } else if (value >= 5 && value < 7) {
      return (this.dataService.getLanguage() === 'de') ? 'Hoch' : "High";
    } else if (value === 7) {
      return (this.dataService.getLanguage() === 'de') ? 'Sehr Hoch' : "Very High";
    }
  }

  private fillSpiderChartData = (module, value, spiderData: Array<SpiderDataModel>, renderOverAllChart: boolean = false): void => {
    spiderData.find(x => {
      if (x.arg === module) {
        if (renderOverAllChart == false) {
          if (value.toString() === '1') {
            x.Niedrig = value;
            x.countLow++
          } else if (value.toString() === '3') {
            x.Mittel = value;
            x.countMedium++
          }
          else if (value.toString() === '5') {
            x.Hoch = value;
            x.countHigh++
          } else if (value.toString() === '7') {
            x.SehrHoch = value;
            x.countVeryHigh++
          }
        }
        else {
          if (1 <= value && value <= 7) {
            x.Niedrig = value;
            x.countLow++
          } else if (7 <= value && value <= 21) {
            x.Mittel = value;
            x.countMedium++
          } else if (21 <= value && value <= 35) {
            x.Hoch = value;
            x.countHigh++
          } else if (35 <= value && value <= 49) {
            x.SehrHoch = value;
            x.countVeryHigh++
          }
        }
      }
    });
  }

  public switchChanged(e): void {
    if (this.selectedSwitch == 'Eintrittswahrscheinlichkeit' || this.selectedSwitch == 'Probability of occurrence') {
      this.spiderData = this.probabilitySpiderData;
    } else if (this.selectedSwitch == 'Schadenshöhe' || this.selectedSwitch == 'Damage level') {
      this.spiderData = this.damageSpiderData;
    } else {
      this.spiderData = this.overAllSpiderData;
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.riskMatrix.onCellPrepared.subscribe(event => {
      event.cellElement.height = 50;
      event.element.style.textAlign = 'center';
      if (event.area === FieldArea.DATA) {
        if (event.rowIndex === 3 || event.columnIndex === 0) {
          event.cellElement.bgColor = 'lime';
        } else if (event.rowIndex === 2 || event.columnIndex === 1) {
          event.cellElement.bgColor = 'yellow';
        } else if (event.rowIndex === 1 || event.columnIndex === 2) {
          event.cellElement.bgColor = 'orange';
        } else {
          event.cellElement.bgColor = 'red';
        }
        event.cellElement.style.textAlign = 'center';
        event.cellElement.style.borderTop = '0px !important';
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  setMatrixHeader(value: string | number) {

    switch (value) {
      case '1': return { text: (this.dataService.getLanguage() === 'de') ? 'Niedrig' : "Low", value: 1 };
      case '3': return { text: (this.dataService.getLanguage() === 'de') ? 'Mittel' : "Medium", value: 3 }
      case '5': return { text: (this.dataService.getLanguage() === 'de') ? 'Hoch' : "High", value: 5 }
      case '7': return { text: (this.dataService.getLanguage() === 'de') ? 'Sehr Hoch' : "Very High", value: 7 }
    }
  }

}


