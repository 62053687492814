import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PersistenceService, TypeKey } from '@wissenswerft/core/data';
import { ModuleViewModel } from '../../view-models/module.view-model';
import { DataDefinitionViewModel, Module, SharedDataService } from '@wissenswerft/organizational-structure';
import { DataService } from '../../../shared/data.service';
import { Column } from 'devextreme/ui/data_grid';
import { VISIBLE_PROPERTIES } from '../../view-models/module.view-model';
import { GridComponent } from '@wissenswerft/ww-library';
import { ModulesService } from './modules.service';
import { DxPopupComponent } from 'devextreme-angular';
import { WindowLayoutComponent } from '@wissenswerft/ww-library';
import { Observable, Subscription } from 'rxjs';
import { ToastType } from '@wissenswerft/ww-library';

@Component({
  selector: 'modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class ModulesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('moduleGrid') moduleGrid: GridComponent;
  @ViewChild('createModulePopup') createModulePopup: DxPopupComponent;
  @ViewChild('windowLayout') windowLayout: WindowLayoutComponent;
  public module: Module;
  private subscriptions: Subscription[] = [];
  public title: string;
  public modules: ModuleViewModel[];
  public columnsHeader: Column[] = [];
  constructor(public dataService: DataService,
    private sharedDataService: SharedDataService,
    private persistenceService: PersistenceService,
    private moduleService: ModulesService) { }

  ngOnInit(): void {
    this.module = new Module(null);
    if (this.sharedDataService.definitionsVM[TypeKey.module]) {
      this.title = this.sharedDataService.definitionsVM[TypeKey.module].namePlural;
    }
    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<Module[]>(TypeKey.module).subscribe(data => {
      const definitions = data[0];
      this.sharedDataService.definitionsVM[TypeKey.module] = definitions;
      this.title = definitions.namePlural;
      const modules = data[1];
      this.modules = [];
      const moduleDefinitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
      this.sharedDataService.definitionsVM[TypeKey.module].moduleDefinitionVM = moduleDefinitionVM;
      const properties = moduleDefinitionVM.properties;
      for (const key in properties) {
        if (properties[key]) {
          const property = properties[key];
          this.columnsHeader.push({
            dataField: property.key,
            caption: property.label,
            visible: property?.visible,
            dataType: this.dataService.getDataType(property.type)
          });
        }
      }
      modules.forEach((module: Module) => {
        const moduleViewModel = new ModuleViewModel(module);
        this.modules.push(moduleViewModel);
      });
    }));
    this.subscriptions.push(this.sharedDataService.updateGridData$.subscribe((module: ModuleViewModel) => {
      this.modules.push(module);
    }));
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.windowLayout.onSaving.subscribe(() => {
        this.createModule();
      }))
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => { subscription.unsubscribe(); });
  }

  public removeRow(event): void {
    const moduleId = event.data.id || event.data.module.id;
    this.persistenceService.addObjectForDelete(moduleId).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Objekt gelöscht', type: ToastType.INFO });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
  }

  public rowUpdated(event): void {
    const moduleId = event.data.id || event.data.module.id;
    const module: ModuleViewModel = new ModuleViewModel(event.data);
    const query = this.moduleService.prepareModulePersistObject(module);
    const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.module].moduleDefinitionVM.multilingualProperties;
    const listProperties = this.sharedDataService.definitionsVM[TypeKey.module].moduleDefinitionVM.listProperties;
    const object = this.sharedDataService.createPersistObject(query, multilingualProperties, listProperties);
    this.persistenceService.addObjectForUpdate(moduleId, object).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
  }

  public openModuleDialog(): void {
    this.createModulePopup.instance.show();
    this.windowLayout.resetField();
  }

  public onClosePopup(): void {
    this.createModulePopup.instance.hide();
  }

  public createModule(): void {
    this.persistObject().subscribe((data: ModuleViewModel) => {
      this.sharedDataService.updateGridData(data);
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
    this.windowLayout.resetField();
  }


  private persistObject(): Observable<any> {
    const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.module].moduleDefinitionVM.multilingualProperties;
    const listProperties = this.sharedDataService.definitionsVM[TypeKey.module].moduleDefinitionVM.listProperties;
    const query = this.sharedDataService.createPersistObject(this.module, multilingualProperties, listProperties);
    return this.persistenceService.addObjectForInsert(TypeKey.module, query);
  }

  public cancel(): void {
    this.module = new Module(null);
    this.windowLayout.resetField();
  }
}
