export class DataDefinition {
    typekey: string;
    embeddable: boolean;
    publishingWorkflow: boolean;
    hierarchical: boolean;
    name: string;
    namePlural: string;
    description: string;
    properties: PropertyDefinition[];

    constructor(data) {
        Object.assign(this, data);
        if (data?.properties) {
            this.properties = [];
            for (let index = 0; index < data.properties.length; index++) {
                const property = data.properties[index];
                this.properties.push(property);
            }
        }
    }

}

export class PropertyDefinition {
    key: string;
    type: string;
    label: string;
    required: boolean;
    list: boolean;
    linkTargetType?: string[];
    visible?: boolean;
    multilingual: boolean;

    constructor(data) {
        Object.assign(this, data);
    }
}

export class Meta {
    created: Date;
    updated: Date;
    itemType: string;
    spaceKey: string;
    parent? : number;
}