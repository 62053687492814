export enum Mode {
    Multiple = "multiple",
    None = "none",
    single = "single",
}

export enum ScrollMode {
    Virtual = "virtual",
    Infinite = "infinite",
    Standard = "standard",
}

export enum ShowScrollbar {
    Always = "always",
    Never = "never",
    OnHover = "onHover",
    OnScroll = "onScroll",
}

export enum FormatExport {
    EXCEL = "excel",
    PDF = "pdf",
}

export enum RowMode {
    Virtual = "virtual",
    Standard = "standard",
}

export enum ShowMode {
    Always = "always",
    none = "none",
    onClick = "onClick",
    onLongTap = "onLongTap",
}

export enum SortOrder {
    None = "undefined",
    Ascending = "asc",
    Descending = "desc"
}

export enum StateStorageType {
    LocalStorage = "localStorage",
    SessionStorage = "sessionStorage",
    Custom = "custom",
}

export enum EditingMode {
    Batch = "batch",
    Cell = "cell",
    Row = "row",
    Form = "form",
    PopUp = "popup"
}

export enum ColumnChooserMode {
    DragAndDrop = "dragAndDrop",
    Select = "select"
}

export enum dxColumnDataType {
    string = 'string',
    number = 'number',
    date = 'date',
    boolean = 'boolean',
    object = 'object',
    datetime = 'datetime'
}

export enum dxColumnType {
    adaptive = 'adaptive',
    buttons = 'buttons',
    detailExpand = 'detailExpand',
    groupExpand = 'groupExpand',
    selection = 'selection'
}

export const FilterConditions = {
    string: ["contains", "anyof", "notcontains", "startswith", "endswith", "=", "<>"],
    object: ["=", "<>", "<", ">", "<=", ">=", "between"]
};

export enum GridIcons {
    COLUMNCHOOSER = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><defs><style>.cls-1{fill:#6e6e7d;}</style></defs><path class="cls-1" d="M340,100V20H20V340H200v40H380V100Zm0,80H240V140H340ZM240,220H340v40H240ZM60,300V60H300v40H200V300Zm180,40V300H340v40Z"/></svg>',
    EXPORTTOEXCEL = '<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400"><defs><style>.cls-1{fill:#6e6e7d;}</style></defs><polygon class="cls-1" points="20 20 20 139.99 60 139.99 60 60 340 60 340 340 60 340 60 260.01 20 260.01 20 380 380 380 380 20 20 20"/><polygon class="cls-1" points="230 200 170 140 170 180 20.01 180 20.01 220 170 220 170 260 230 200"/></svg>'
  }





  
