import { ProfileInfo } from "@wissenswerft/core/authentication";
import { SubProject } from "@wissenswerft/organizational-structure";
import { NamedItem, Tag, MeasureBenefit } from "./organizational-structure.model";

export class MeasureRecommendation extends NamedItem {
    goal?: string; // RichText
    tags: Tag[];
    benefits: MeasureBenefit[] | number[];
    subProject: SubProject;
    completionDate: Date;
    initialSituation: string; // RichText
    originalTargetDate: Date;
    releaseDate: Date;
    areaManager: ProfileInfo;
    responsibleConsultant: ProfileInfo;
    title?: string;

    constructor(data) {
        super();
        Object.assign(this, data);
    }
}