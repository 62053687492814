import { Injectable } from '@angular/core';
import { LegalBasis } from '@wissenswerft/organizational-structure';
import { LegalBasisViewModel } from '../../view-models/legal-basis.view-model'
@Injectable({
  providedIn: 'root'
})
export class LegalBasisService {

  constructor() {
  }

  public prepareLegalBasisiPersistObject(legalBasis: LegalBasisViewModel): LegalBasis {
    const query: LegalBasis = new LegalBasis();
    query.label = legalBasis.label;
    query.ident = legalBasis.ident.replace(/\s/g, '');
    query.description = legalBasis.description;
    query.link = legalBasis.link;
    return query;
  }
}
