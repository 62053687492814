import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'apps/cmt/src/app/shared/data.service';
import { Measure, MeasureDefinition, MeasureTask, OrgaTech, PrevDectEng, PrevDectDe, Status } from '../../../models/measure.model';
import { ConvertHelper, SpaceOneCoreDataService, PersistenceService, TypeKey } from '@wissenswerft/core/data';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { DataDefinitionViewModel, MeasureBenefit, SharedDataService } from '@wissenswerft/organizational-structure';
import { RiskAssesmentMeasure } from '../../../models/customer-space.model';
import { concatMap, tap, map, switchMap } from 'rxjs/operators';
import { RiskAssessment } from '../../../models/risk-assesment.model';
import { Column } from 'devextreme/ui/data_grid';
import { ActivatedRoute, Router } from '@angular/router';
import { VISIBLE_PROPERTIES, MEASURE_TASK_VISIBLE_PROPERTIES } from '../../../view-models/measure.view-model';
import { MeasureService } from '../measure.service';
import { GridComponent, LabelMode, StylingMode } from '@wissenswerft/ww-library';
import { ProfileInfo } from '@wissenswerft/core/authentication';
import { RiskAssessmentService } from '../../risk-assesment/risk-assessment.service';
import { DxPopupComponent } from 'devextreme-angular';
import { ClickOrigin, Source } from '../../../models/home.model';
import { Comment } from 'libs/core/comments/src/lib/models/comment.model';
import { ToastType } from '@wissenswerft/ww-library';
import { AppService } from 'apps/cmt/src/app/app.service';
import { ResourceManagerService } from '@wissenswerft/core/resources';

@Component({
  selector: 'cmt-measure-detail',
  templateUrl: './measure-detail.component.html',
  styleUrls: ['./measure-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeasureDetailComponent implements OnInit, OnDestroy {
  @Input() createMeasure = false;
  @ViewChild('measureTasksGrid', { static: false }) measureTasksGrid: GridComponent;
  @ViewChild('createRiskPopup') createRiskPopup: DxPopupComponent;
  public labelMode = LabelMode.Floating;
  public stylingMode = StylingMode.Outlined;

  private measureId;
  public currentScope: string;
  public measure: Measure;
  public allComments: Comment;
  public measureComments: Comment;
  public measureBenefits: Observable<MeasureBenefit[]>;
  public measureTasks: MeasureTask[] = [];
  public measureDefinitions: Observable<MeasureDefinition[]>;
  public assignedRisks: RiskAssesmentMeasure[] = [];
  private measureTasksDefinition: DataDefinitionViewModel;
  public allRisks: RiskAssessment[] = [];
  private newRisk;
  private addedRisk;
  private selectedRisk: RiskAssessment;
  public assignedRisksColumns: Column[];
  private subscriptions: Subscription[] = [];
  public currentMeasureIndex: number;
  public toogleTitleVisible: boolean = false;
  public tasksColumns: Column[] = [];
  public orgaTech = ConvertHelper.ToArray(OrgaTech);
  public responsibles: Observable<ProfileInfo[]>;
  public prevDectDe = ConvertHelper.ToArray(PrevDectDe);
  public prevDectEng = ConvertHelper.ToArray(PrevDectEng);
  public prevDect = [];

  constructor(
    private coreDataService: SpaceOneCoreDataService, private persistenceService: PersistenceService, private cdr: ChangeDetectorRef, private sharedDataService: SharedDataService,
    public dataService: DataService, private activatedRoute: ActivatedRoute, private router: Router ,private resourceManagerService: ResourceManagerService,
    public measureService: MeasureService, private riskService: RiskAssessmentService) {

    this.measureId = this.activatedRoute.snapshot.params.id;
    this.subscriptions.push(this.coreDataService.getAllComments<Comment>().subscribe(data => {
      this.allComments = data;
    }));
  }

  ngOnInit(): void {
    if (this.resourceManagerService.isDefaultLanguageDe) {
      this.prevDect = this.prevDectDe;
    }
    else { this.prevDect = this.prevDectEng;}
    if (!this.measureService.measures) {
      this.sharedDataService.getDefinitionAndData<Measure[]>(TypeKey.measure).subscribe((data) => {
        this.measureService.measures = data[1];
      });
    }
    this.responsibles = this.coreDataService.getAllAccounts<ProfileInfo[]>();
    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<MeasureTask[]>(TypeKey.measureTask).subscribe((data) => {
      const definitions = data[0];
      const measureTasks = data[1];
      const measureDefinitionVM = new DataDefinitionViewModel(definitions, MEASURE_TASK_VISIBLE_PROPERTIES);
      this.measureTasksDefinition = measureDefinitionVM;
      const properties = measureDefinitionVM.properties;
      for (const key in properties) {
        if (properties[key]) {
          const property = properties[key];
          this.tasksColumns.push({
            dataField: property.key,
            caption: property.label,
            visible: property?.visible,
            dataType: this.dataService.getDataType(property.type),
            width: (property.type === 'NUMBER') ? '30%' : '50%',
            groupIndex: (property.key === 'subModule') ? 0 : undefined,
            lookup: (this.getLookupColumn(property.key)) ? {
              dataSource: this.getLookupColumn(property.key).dataSource,
              displayExpr: this.getLookupColumn(property.key).displayExpr,
              valueExpr: this.getLookupColumn(property.key).valueExpr
            } : undefined
          });
        }
      }
      measureTasks.forEach((measureTask => {
        if (measureTask?.measure?.toString() === this.measureId) {
          this.measureTasks.push(measureTask);
        }
      }));
    }));

    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<RiskAssessment[]>(TypeKey.riskAssessment)
      .subscribe((data) => {
        const definitions = data[0];
        const riskAssessments = data[1];
        const riskAssessmentDefinitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
        this.riskService.riskAssessmentDefinitionVM = riskAssessmentDefinitionVM;
        this.allRisks = riskAssessments;
        this.assignedRisksColumns = [
          {
            dataField: 'riskIssue.id', dataType: 'string', caption: 'Risiko', width: '80%', cellTemplate: "cellTemplate", lookup: {
              dataSource: riskAssessments,
              displayExpr: 'title',
              valueExpr: 'id'
            }
          },
          {
            dataField: 'value', caption: 'Riskoreduktion', width: '20%', alignment: 'center',
            lookup: {
              dataSource: this.getLookupColumn('Riskoreduktion').dataSource,
              displayExpr: this.getLookupColumn('Riskoreduktion').displayExpr,
              valueExpr: this.getLookupColumn('Riskoreduktion').valueExpr
            }
          }
        ];
      }));

    this.measureBenefits = this.coreDataService.getItemsByName(TypeKey.measureBenefit);
    this.measureDefinitions = this.coreDataService.getItemsByName(TypeKey.measureDefinition);
    this.subscriptions.push(this.dataService.getRiskAssessmentMeasures()
      .pipe(map((data) => {
        this.assignedRisks = [];
        const riskAssessmentMeasures: RiskAssesmentMeasure[] = data[0] as RiskAssesmentMeasure[];
        riskAssessmentMeasures.forEach((riskAssessmentMeasure => {
          const ram = new RiskAssesmentMeasure(riskAssessmentMeasure);
          if (ram?.measure?.id === this.measure.id && ram.type === 'assigned') {
            this.assignedRisks.push(ram);
          }
        }));
        return riskAssessmentMeasures;
      })).subscribe());

    this.dataService.getMeasureDetail().subscribe(measureDetail => {
      if (this.measureService.measures) {
        this.currentMeasureIndex = this.measureService.measures.findIndex(x => x.id === measureDetail.id);
      }
      this.measure = measureDetail;
    });
    this.subscriptions.push(this.coreDataService.getCommentsByItemId<Comment>(this.measureId).subscribe(data => {
      this.measureComments = data;
    }));
    if (!this.measure?.id) {
      this.subscriptions.push(this.coreDataService.getDynamicContentItemById<Measure>(this.measureId).subscribe((measure) => {
        this.measure = measure;
        this.cdr.detectChanges();
      }));
    }
    this.cdr.detectChanges();
  }


  public persistChanges(event) {
    const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.measure].measureDefinitionVM.multilingualProperties;
    delete this.measure.meta.updated;
    const query: any = Object.assign({}, this.measure);
    multilingualProperties.forEach(prop => {
      query[prop.key] = { "de": query[prop.key], 'en': query[prop.key] };
    });
    this.subscriptions.push(this.persistenceService.addObjectForUpdate(this.measure.id, query).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  public onCellPrepared = (e): void => {
    if (e.column.dataType == 'number') {
      e.cellElement.style.textAlignLast = 'center';
    }
  }

  private getLookupColumn(key: string) {
    switch (key) {
      case 'degreeOfImplementation':
      case 'efficiency':
      case 'Riskoreduktion':
        return { valueExpr: 'id', displayExpr: 'value', dataSource: this.measureService.degree };
    }
  }

  public addRow(): void {
    this.measureTasksGrid.addRow();


  }

  public toggleTitle = (): void => {
    this.toogleTitleVisible = !this.toogleTitleVisible;
  }

  private addPercentSymbol = (arg): string => {
    return arg.valueText + '%';
  }

  getLastUpdatedDateTime() {
    const updated = new Date(this.measure.meta.updated);
    return updated.toLocaleDateString() + ' ' + updated.toLocaleTimeString();
  }

  customizeText(arg: any) {
    return arg.valueText + " %";
  }

  average(target) {
    let sum = 0;
    let tasks = 0;
    this.measureTasks?.forEach((task) => {
      sum += task[target];
      tasks++;
    });
    const average = sum / tasks || 0;
    if (average > 0 && average < 100) {
      this.measure.status = Status.inprogress;
    } else if (average === 100) {
      this.measure.status = Status.done;
    } else {
      this.measure.status = Status.open;
    };
    return average;
  }

  public addTask(): void {
    this.measureTasksGrid.addEmptyRow();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public addRiksAllocation(event): void {
    let index = this.allRisks.findIndex((element) => element.title === this.selectedRisk?.title);
    if (this.isAssigned() == false) {
      if (index !== -1) {
        const query: RiskAssesmentMeasure = new RiskAssesmentMeasure({
          measure: this.measure,
          riskIssue: this.allRisks[index],
          type: 'assigned',
          value: 0
        });
        this.subscriptions.push(this.persistenceService.addObjectForInsert(TypeKey.riskAssessmentMeasure, query).subscribe((data) => {
          this.assignedRisks.push(query);
        }));
      }
      else {
        this.createRiskPopup.instance.show();
      }
    }
    if (this.isAssigned() == undefined) {
      this.createRiskPopup.instance.show();
    }
  }

  private isAssigned(): boolean {
    let isAssigned = false;
    if (this.assignedRisks?.length !== 0) {
      this.assignedRisks.forEach((element) => {
        if (element.riskIssue?.title === this.selectedRisk?.title)
          isAssigned = true;
      });
    }
    return isAssigned;
  }

  addCustomRisk(data) {
    this.selectedRisk = null;
    this.addedRisk = data;
    if (!data.text) {
      data.customItem = null;
      return;
    }
    const taskIds = this.measureTasks.map(function (item) {
      return item.id;
    });
    const incrementedId = Math.max.apply(null, taskIds) + 1;
    this.newRisk = {
      title: data.text,
      id: incrementedId
    };
    data.customItem = this.newRisk;
    return this.newRisk;
  }

  selectRisk(event) {
    this.selectedRisk = event.selectedItem;
    this.addedRisk = null;
  }

  public assignTaskToMeasure(event) {
    const query = this.measureService.prepareRiskPersistObject(event.changes[0].data, this.measure);
    const multilingualProperties = this.measureTasksDefinition.multilingualProperties;
    const listProperties = this.measureTasksDefinition.listProperties;
    const object = this.sharedDataService.createPersistObject(query, multilingualProperties, listProperties);
    this.subscriptions.push(this.persistenceService.addObjectForInsert(TypeKey.measureTask, object).subscribe((task) => {
      this.measureTasks.pop();
      this.measureTasks.push(task);
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  updateTask(event) {
    let query = this.measureService.prepareRiskPersistObject(event.data, this.measure);
    const multilingualProperties = this.measureTasksDefinition.multilingualProperties;
    const listProperties = this.measureTasksDefinition.listProperties;
    const object = this.sharedDataService.createPersistObject(query, multilingualProperties, listProperties);
    this.subscriptions.push(this.persistenceService.addObjectForUpdate(event.data.id, object).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  deleteTask(event) {
    this.subscriptions.push(this.persistenceService.addObjectForDelete(event.data.id).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Objekt gelöscht', type: ToastType.INFO });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  public setResponsible(e) {
    return e ? e.id : null;
  }

  public onResponsibleChange(e) {
    this.measure.responsible = new ProfileInfo(this.sharedDataService.responsibleById[e.value]);
  }

  public getResponsibleFullName(responsible: ProfileInfo) {
    if (responsible) {
      return responsible.lastname + ', ' + responsible.firstname;
    }
  }
  public onClosePopup(): void {
    this.createRiskPopup.instance.hide();
  }

  public removeRow(event): void {
    this.subscriptions.push(this.persistenceService.addObjectForDelete(event.data.id || event.data.riskIssue.id).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Objekt gelöscht', type: ToastType.INFO });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
  }

  public updateRow(event): void {
    const query = this.dataService.prepareRiskAssessmentUpdate(event.data);
    this.subscriptions.push(this.persistenceService.addObjectForUpdate(event.data.id, query).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    }));
    // const riskDetail = new RiskAssessment(event.data.riskIssue);
    // this.persistenceService.addObjectForUpdate
    // this.dataService.sendRiskDetail({
    //   riskAssessment: riskDetail,
    //   riskDefinition: this.riskService.riskAssessmentDefinitionVM
    // });
    // this.router.navigate(['riskAssessmentDetail', riskDetail.id]);
  }


  public backToMeasureList(): void {
    if (this.dataService.getClickOrigin()?.source == Source.RiskDetail) {
      this.router.navigate(['riskAssessmentDetail', this.dataService.getClickOrigin().id]);
      this.dataService.appService.showMeasureIcon = false;
      this.dataService.appService.showRiskIcon = true;
      this.dataService.appService.selectTreeItem(2);
      this.dataService.setClickOrigin({ id: this.measure.id, source: Source.MeasureDetail, value: true });
    }
    else {
      this.dataService.appService.showMeasureIcon = false;
      this.router.navigateByUrl('measures');
    }
  }

  public linkClick(e) {
    if (e.rowType === 'data' && e.column.dataField === "riskIssue.id") {
      const riskSource: ClickOrigin = new ClickOrigin({ id: this.measure.id, source: Source.MeasureDetail, value: true });
      this.dataService.setClickOrigin(riskSource);
      this.dataService.appService.showMeasureIcon = false;
      this.dataService.appService.showRiskIcon = true;
      const riskDetail = new RiskAssessment(e.data.riskIssue);
      this.dataService.sendRiskDetail({
        riskAssessment: riskDetail,
        riskDefinition: this.riskService.riskAssessmentDefinitionVM
      });
      this.dataService.appService.selectTreeItem(2);
      this.router.navigate(['riskAssessmentDetail', e.data.riskIssue.id]);
    }
  }

  public navigateToMeasure = (index: number): void => {
    this.measureTasks = [];
    this.assignedRisks = [];
    this.currentMeasureIndex = this.currentMeasureIndex + index;
    const measureDetail = new Measure(this.measureService.measures[this.currentMeasureIndex]);
    const measureData = forkJoin([
      this.coreDataService.getDynamicContentItemById<Measure>(measureDetail.id),
      this.sharedDataService.getDefinitionAndData<MeasureTask[]>(TypeKey.measureTask),
      this.dataService.getRiskAssessmentMeasures()
    ])
    measureData.subscribe(data => {
      this.dataService.sendMeasureDetail(data[0]);
      const measureTasks = data[1][1];
      measureTasks.forEach((measureTask => {
        if (measureTask?.measure?.toString() === this.measure.id.toString()) {
          this.measureTasks.push(measureTask);
          this.cdr.detectChanges();
        }
      }));
      const riskAssessmentMeasures: RiskAssesmentMeasure[] = data[2] as RiskAssesmentMeasure[];
      riskAssessmentMeasures.forEach((riskAssessmentMeasure => {
        const ram = new RiskAssesmentMeasure(riskAssessmentMeasure);
        if (ram?.measure?.id === this.measure.id && ram.type === 'assigned') {
          this.assignedRisks.push(ram);
        }
      }));
    })
  }
}