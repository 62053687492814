import { RiskAssesmentMeasure } from "@wissenswerft/organizational-structure";

export class RiskAssesmentMeasureModel extends RiskAssesmentMeasure {
    value: number;
    type: RiskAssesmentMeasureType;
    constructor(data) {
        super(data);
        Object.assign(this, data);
    }
}

export enum RiskAssesmentMeasureType {
    SUGGESTION = 'suggestion',
    ASSIGNED = 'assigned'
}