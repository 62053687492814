import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { RiskAssessment } from '../../../models/risk-assesment.model';
import { Module, SharedDataService, SubModule } from '@wissenswerft/organizational-structure';
import { SpaceOneCoreDataService, PersistenceService, TypeKey } from '@wissenswerft/core/data';
import { DataService } from '../../../../shared/data.service';
import { RiskAssessmentViewModel } from '../../../view-models/risk-assessment.view-model';
import { Router } from '@angular/router';
import { RiskAssessmentService } from '../risk-assessment.service';
import { ToastType } from '@wissenswerft/ww-library';
import { LabelMode, StylingMode } from '@wissenswerft/ww-library';

@Component({
  selector: 'cmt-risk-assessment-create',
  templateUrl: './risk-assessment-create.component.html',
  styleUrls: ['./risk-assessment-create.component.scss']
})
export class RiskAssessmentCreateComponent implements OnInit, OnDestroy {
  public risk: RiskAssessment;
  public subModules: Observable<SubModule[]>;
  public selectedModule: Module;
  private subscriptions: Subscription[] = [];
  @Output() closePopup: EventEmitter<any> = new EventEmitter();
  public labelMode = LabelMode.Floating ;
  public stylingMode= StylingMode.Outlined;
  public createSecondRisk: boolean = false;

  constructor(private coreDataService: SpaceOneCoreDataService, private persistenceService: PersistenceService, private sharedDataService : SharedDataService,
    public dataService: DataService, private router: Router, public riskService: RiskAssessmentService) {

      this.subscriptions.push(this.riskService.getSelectedModule().subscribe((module => {
      this.selectedModule = module;
      // this.onSelectModule({
      //   itemData: module
      // });
    })));

  }

  ngOnInit(): void {
    this.risk = new RiskAssessment(null);
    this.riskService.prepareModules();    
    // this.responsibles = this.coreDataService.getAllAccounts();
    // this.modules = this.coreDataService.getItemsByName<Module[]>(TypeKey.module);
  }

  public onCancelClick(event: Event): void {
    // this.risk = new RiskAssessment({});
    this.closePopup.emit(true);
    this.clearForm();
  }

  public onRiskSaveClick(event: Event): void {
    event.preventDefault();
    this.risk.ident = this.risk.title;
    this.selectedModule = null;
    this.subscriptions.push(this.persistObject().subscribe((data: RiskAssessmentViewModel) => {
      this.sharedDataService.updateGridData(data);
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
      if (this.createSecondRisk === true) {
        this.risk = new RiskAssessment(null);
      } else {
        this.closePopup.emit(true);
      }
    }, error => {
      this.dataService.appService.callNotification({ message: 'Pflichtfeld', type: ToastType.ERROR });
    }));
    this.clearForm();
  }

  public onCreateDisplayDetailClick(event: Event) {
    event.preventDefault();
    this.subscriptions.push(this.persistObject().subscribe((riskDetail) => {
      this.dataService.sendRiskDetail({
        riskAssessment: riskDetail,
        riskDefinition: this.riskService.riskAssessmentDefinitionVM
      });
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
      this.router.navigate(['riskAssessmentDetail', riskDetail.id]);
    }, error => {
      this.dataService.appService.callNotification({ message: 'Pflichtfeld', type: ToastType.ERROR });
    }));
    this.closePopup.emit(true);
  }

  public getRsponsibleFullName(responsible) {
    return responsible ? responsible?.lastname + ', ' + responsible?.firstname : '';
  }

  public onSelectModule(event) {
    this.subModules = this.coreDataService.getItemsBySpoqlQuery('subModule', `property 'module' eq ${event.itemData.id}`);
  }

  public persistObject(): Observable<any> {
    const multilingualProperties = this.riskService.riskAssessmentDefinitionVM.multilingualProperties;
    const listProperties = this.riskService.riskAssessmentDefinitionVM.listProperties;
    const query = this.sharedDataService.createPersistObject(this.risk, multilingualProperties, listProperties);
    return this.persistenceService.addObjectForInsert(TypeKey.riskAssessment, query);
  }

  private clearForm(): void {
    this.risk = new RiskAssessment(null);
    // this.riskService.modules = null;
    this.sharedDataService.responsibles = null;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
