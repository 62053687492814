import { Component, Input, Output, EventEmitter, ViewEncapsulation, ViewChild } from '@angular/core';
import { ResourceManager } from '@wissenswerft/core/resources';
import { DxTextBoxComponent, DxValidatorComponent } from 'devextreme-angular';
import { LabelMode, StylingMode, ValidationStatus } from '../ww-models/ww-enums';

@Component({
    selector: 'ww-window-layout',
    template: `<form (submit)="save($event)">
        <div fxLayout="column" fxFlexFill>
        <div *ngIf="showIdentWLayout" fxLayout="row" fxLayoutGap="5px">
            <div fxLayout="column" fxFlex="100">
                <dx-text-box #ident [validationStatus]="validtionStatus" [stylingMode]="stylingMode" [width]="'100%'" [(value)]="windowData.ident" [label]="'Ident'" [labelMode]="labelMode">
                <dx-validator #identValidator>
                    <dxi-validation-rule type="required"  message="{{res('Ww-Library-Required')}}"></dxi-validation-rule>
                </dx-validator>
                </dx-text-box>
            </div>
        </div>
        <div fxLayout="row" fxLayoutGap="5px" fxFlexOffset="10px">
            <div fxLayout="column" fxFlex="100">
                <dx-text-box #label [validationStatus]="validtionStatus" [(value)]="windowData.label" [stylingMode]="stylingMode" [width]="'100%'" [label]="'Label'" [labelMode]="labelMode"> 
                    <dx-validator #labelValidator>
                        <dxi-validation-rule type="required"  message="{{res('Ww-Library-Required')}}"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </div>
        </div>
        <div fxLayout="column" fxFlexOffset="30px">
            <div style="align-self: flex-end;" fxLayout="row" fxLayoutGap="20px">
                <div fxLayout="column">
                    <dx-button class="windowCreateBackground" [text]="res('Ww-Library-Create')" id="button"
                    type="button"
                    [useSubmitBehavior]="true"></dx-button>
                </div>
                <div fxLayout="column">
                    <dx-button [text]="res('Ww-Library-Cancel')" (onClick)="cancel()"></dx-button>
                </div>
            </div>
        </div>
    </div>
    </form>`,
    encapsulation: ViewEncapsulation.Emulated
})
export class WindowLayoutComponent {
    @ViewChild('ident') dxIdentTextBox: DxTextBoxComponent;
    @ViewChild('label') dxLabelTextBox: DxTextBoxComponent;
    @Output() closePopup: EventEmitter<any> = new EventEmitter();
    @Output() onSaving: EventEmitter<any> = new EventEmitter();
    @ViewChild('labelValidator') labelValidator: DxValidatorComponent;
    @ViewChild('identValidator') identValidator: DxValidatorComponent;
    public res = ResourceManager.getResources("ww");
    public validtionStatus = ValidationStatus.VALID;
    private _windowData: {
        label: string, ident: string
    } = { ident: '', label: '' };
    @Input()
    get windowData() { return this._windowData; }
    set windowData(windowData) {
        this._windowData = windowData;
    }

    @Input() labelMode = LabelMode.Floating;
    @Input() stylingMode = StylingMode.Outlined;
    @Input() showIdentWLayout = true;


    public cancel(): void {
        this.resetField();
        this.closePopup.emit(true);
    }

    public save(event: Event): void {
        event.preventDefault();
        this.onSaving.emit();
        this.closePopup.emit(true);
        this.resetField();
    }

    public resetField(): void {
        this.windowData.label = '';
        this.windowData.ident = '';
        this.validtionStatus = ValidationStatus.VALID;
        this.identValidator?.instance.reset();
        this.labelValidator?.instance.reset();
        if (this.dxIdentTextBox) {
            this.dxIdentTextBox.validationStatus = ValidationStatus.VALID;
        }
        this.dxLabelTextBox.validationStatus = ValidationStatus.VALID;
    }
}
