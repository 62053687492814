import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataDefinitionViewModel, RiskIssue, SharedDataService } from '@wissenswerft/organizational-structure';
import { TypeKey } from '@wissenswerft/core/data';
import { DxFormComponent, DxPopupComponent, DxSelectBoxComponent, DxTextBoxComponent, DxValidatorComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { PersistenceService } from 'libs/core/data/src/lib/persistence.service';
import { DataService } from '../../../shared/data.service';
import { GridComponent, ValidationStatus } from '@wissenswerft/ww-library';
import { RiskAssesmentMeasureService } from './risk-Assesment-Measure.service';
import { RiskAssesmentMeasureViewModel, VISIBLE_PROPERTIES } from '../../view-models/risk-assesment-measure.view-model'
import { Observable, Subscription } from 'rxjs';
import { WindowLayoutComponent } from '@wissenswerft/ww-library';
import { Measure } from '../../models/measure.model';
import { RiskAssesmentMeasureModel } from '../../models/risk-assesment-measure.model';
import { ToastType } from '@wissenswerft/ww-library';

@Component({
  selector: 'risk-Assesment-Measure',
  templateUrl: './risk-Assesment-Measure.component.html',
  styleUrls: ['./risk-Assesment-Measure.component.scss']
})
export class RiskAssesmentMeasureComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('riskAMeasurePlanGrid') riskAMeasurePlanGrid: GridComponent;
  @ViewChild('windowLayout') windowLayout: WindowLayoutComponent;
  @ViewChild('createRamPopup') createRamPopup: DxPopupComponent;
  @ViewChild('value') dxValueTextBox: DxTextBoxComponent;
  @ViewChild('type') dxTypeTextBox: DxTextBoxComponent;
  @ViewChild('riskIssue') dxRiskIssueSelectBox: DxSelectBoxComponent;
  @ViewChild('measureList') dxMeasureListSelectBox: DxSelectBoxComponent;
  @ViewChild('form') form: DxFormComponent;

  public riskAssesmentMeasure: RiskAssesmentMeasureModel;
  public title: string;
  public riskAssesmentMeasures: RiskAssesmentMeasureViewModel[];
  private subscriptions: Subscription[] = [];
  public columnsHeader: Column[] = [];
  public risksIsuue: RiskIssue[] = [];
  public measures: Measure[] = [];

  public createButtonOptions = {
    text: this.dataService.res('Cmt-CreateRisk-Create'),
    useSubmitBehavior: true
  };

  public cancelButtonOptions = {
    text: this.dataService.res('Cmt-CreateRisk-Cancel'),
    onClick: () => this.clearWindow()
  };

  constructor(public dataService: DataService,
    private persistenceService: PersistenceService,
    private sharedDataService: SharedDataService,
    public riskAssesmentMeasureService: RiskAssesmentMeasureService) {
    this.subscriptions.push(this.riskAssesmentMeasureService.risksIssue.subscribe(riskAssesmentMeasures => {
      this.risksIsuue = riskAssesmentMeasures;
    }));
    this.subscriptions.push(this.riskAssesmentMeasureService.measures.subscribe(measures => {
      this.measures = measures;
    }));
  }



  ngOnInit(): void {
    this.riskAssesmentMeasure = new RiskAssesmentMeasureModel(null);
    if (this.sharedDataService.definitionsVM[TypeKey.riskAssessmentMeasure]) {
      this.title = this.sharedDataService.definitionsVM[TypeKey.riskAssessmentMeasure].namePlural;
    }

    this.subscriptions.push(this.sharedDataService.updateGridData$.subscribe((riskAssesmentMeasure: RiskAssesmentMeasureViewModel) => {
      this.riskAssesmentMeasures.push(riskAssesmentMeasure);
    }));
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<RiskAssesmentMeasureModel[]>(TypeKey.riskAssessmentMeasure).subscribe(data => {
      const definitions = data[0];
      this.sharedDataService.definitionsVM[TypeKey.riskAssessmentMeasure] = definitions;
      this.title = definitions.namePlural;
      const riskAssesmentMeasures = data[1];
      this.riskAssesmentMeasures = [];
      const riskAMeasureDefinitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
      this.sharedDataService.definitionsVM[TypeKey.riskAssessmentMeasure].definitionVM = riskAMeasureDefinitionVM;
      const properties = riskAMeasureDefinitionVM.properties;
      for (const key in properties) {
        if (properties[key]) {
          const property = properties[key];
          this.columnsHeader.push({
            dataField: property.key,
            caption: property.label,
            visible: property?.visible,
            dataType: this.dataService.getDataType(property.type),
            lookup: (property.key === 'measure' || property.key === 'riskIssue' || property.type === 'ENUM') ? {
              valueExpr: (property.type === 'ENUM') ? 'type' : 'id',
              displayExpr: (property.type === 'ENUM') ? 'type' : 'title',
              dataSource: (property.type === 'ENUM') ? [{ type: 'suggestion' }, { type: 'assigned' }] :
                (property.key === 'riskIssue') ? this.risksIsuue : this.measures
            } : undefined
          });
        }
      }
      riskAssesmentMeasures.forEach((riskAssesmentMeasure: RiskAssesmentMeasureModel) => {
        this.riskAssesmentMeasures.push(new RiskAssesmentMeasureViewModel(riskAssesmentMeasure));
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => { subscription.unsubscribe(); });
  }

  public removeRow(event): void {
    const riskAssesmentMeasureId = event.data.id || event.data.riskAssesmentMeasure.id;
    this.persistenceService.addObjectForDelete(riskAssesmentMeasureId).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Objekt gelöscht', type: ToastType.INFO });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
  }

  public rowUpdated(event): void {
    const riskAssesmentMeasureId = event.data.id || event.data.riskAssesmentMeasure.id;
    const riskAssesmentMeasure: RiskAssesmentMeasureModel = new RiskAssesmentMeasureModel(event.data.riskAssesmentMeasure);
    const query = this.riskAssesmentMeasureService.prepareRiskAMeasurePersistObject(riskAssesmentMeasure);
    const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.riskAssessmentMeasure].definitionVM.multilingualProperties;
    const listProperties = this.sharedDataService.definitionsVM[TypeKey.riskAssessmentMeasure].definitionVM.listProperties;
    const object = this.sharedDataService.createPersistObject(query, multilingualProperties, listProperties);
    this.persistenceService.addObjectForUpdate(riskAssesmentMeasureId, object).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
  }

  public openRiskAMeasureDialog(): void {
    this.form.instance.resetValues();
    this.createRamPopup.instance.show();
  }

  public createRiskAMeasure(event: Event): void {
    event.preventDefault();
    this.persistObject().subscribe((data: RiskAssesmentMeasureModel) => {
      const riskAssesmentMeasure = new RiskAssesmentMeasureViewModel(data);
      this.sharedDataService.updateGridData(riskAssesmentMeasure);
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
      this.clearWindow();
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
    this.riskAssesmentMeasure = new RiskAssesmentMeasureModel(null);
    this.form.instance.resetValues();
  }

  private persistObject(): Observable<RiskAssesmentMeasureModel> {
    const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.riskAssessmentMeasure].definitionVM.multilingualProperties;
    const listProperties = this.sharedDataService.definitionsVM[TypeKey.riskAssessmentMeasure].definitionVM.listProperties;
    const query = this.sharedDataService.createPersistObject(this.riskAssesmentMeasure, multilingualProperties, listProperties);
    return this.persistenceService.addObjectForInsert(TypeKey.riskAssessmentMeasure, query);
  }

  public clearWindow(): void {
    this.createRamPopup.instance.hide();
    this.form.instance.resetValues();
  }
}


