import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataDefinitionViewModel, ResponsiblePlan, SharedDataService } from '@wissenswerft/organizational-structure';
import { TypeKey } from '@wissenswerft/core/data';
import { DxFormComponent, DxPopupComponent, DxSelectBoxComponent, DxTextBoxComponent, DxValidatorComponent } from 'devextreme-angular';
import { Column } from 'devextreme/ui/data_grid';
import { PersistenceService } from 'libs/core/data/src/lib/persistence.service';
import { DataService } from '../../../shared/data.service';
import { GridComponent, ValidationStatus } from '@wissenswerft/ww-library';
import { ResponsiblePlanService } from './responsible-Plan.service';
import { ResponsiblePlanViewModel, VISIBLE_PROPERTIES } from '../../view-models/responsible-plan.view-model'
import { Observable, Subscription } from 'rxjs';
import { WindowLayoutComponent } from '@wissenswerft/ww-library';
import { ProfileInfo } from '@wissenswerft/core/authentication';
import { Measure } from '../../models/measure.model';
import { ToastType } from '@wissenswerft/ww-library';

@Component({
  selector: 'responsible-Plan',
  templateUrl: './responsible-Plan.component.html',
  styleUrls: ['./responsible-Plan.component.scss']
})
export class ResponsiblePlanComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('responsiblePlanGrid') responsiblePlanGrid: GridComponent;
  @ViewChild('windowLayout') windowLayout: WindowLayoutComponent;
  @ViewChild('createPlanPopup') createPlanPopup: DxPopupComponent;
  @ViewChild('plannedDays') dxPlannedDaysTextBox: DxTextBoxComponent;
  @ViewChild('remaingDays') dxRemaingDaysTextBox: DxTextBoxComponent;
  @ViewChild('responsible') dxResponsiblSelectBox: DxSelectBoxComponent;
  @ViewChild('measureList') dxMeasureSelectBox: DxSelectBoxComponent;
  @ViewChild('form') form: DxFormComponent;

  public responsiblePlan: ResponsiblePlan;
  public title: string;
  public responsiblePlans: ResponsiblePlanViewModel[];
  private subscriptions: Subscription[] = [];
  public columnsHeader: Column[] = [];
  public responsibles: ProfileInfo[] = [];
  public measures: Measure[] = [];

  public createButtonOptions = {
    text: this.dataService.res('Cmt-CreateRisk-Create'),
    useSubmitBehavior: true
  };

  public cancelButtonOptions = {
    text: this.dataService.res('Cmt-CreateRisk-Cancel'),
    onClick: () => this.clearWindow()
  };

  constructor(public dataService: DataService,
    private sharedDataService: SharedDataService,
    private persistenceService: PersistenceService,
    private responsiblePlanService: ResponsiblePlanService) {
    this.subscriptions.push(this.sharedDataService.responsibles.subscribe(responsibles => {
      this.responsibles = responsibles;
    }));
    this.subscriptions.push(this.responsiblePlanService.measures.subscribe(measures => {
      this.measures = measures;
    }));
  }

  ngOnInit(): void {
    this.responsiblePlan = new ResponsiblePlan(null);
    if (this.sharedDataService.definitionsVM[TypeKey.responsiblePlan]) {
      this.title = this.sharedDataService.definitionsVM[TypeKey.responsiblePlan].namePlural;
    }

    this.subscriptions.push(this.sharedDataService.updateGridData$.subscribe((responsiblePlan: ResponsiblePlanViewModel) => {
      this.responsiblePlans.push(responsiblePlan);
    }));
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.sharedDataService.getDefinitionAndData<ResponsiblePlan[]>(TypeKey.responsiblePlan).subscribe(data => {
      const definitions = data[0];
      this.sharedDataService.definitionsVM[TypeKey.responsiblePlan] = definitions;
      this.title = definitions.namePlural;
      const responsiblePlans = data[1];
      this.responsiblePlans = [];
      const responsibleDefinitionVM = new DataDefinitionViewModel(definitions, VISIBLE_PROPERTIES);
      this.sharedDataService.definitionsVM[TypeKey.responsiblePlan].definitionVM = responsibleDefinitionVM;
      const properties = responsibleDefinitionVM.properties;
      for (const key in properties) {
        if (properties[key]) {
          const property = properties[key];
          this.columnsHeader.push({
            dataField: property.key,
            caption: property.label,
            visible: property?.visible,
            dataType: this.dataService.getDataType(property.type),
            lookup: (property.type === 'ACCOUNT' || property.type === 'LINK') ? {
              valueExpr: 'id',
              displayExpr: (property.type === 'ACCOUNT') ? this.getRsponsibleFullName : 'title',
              dataSource: (property.type === 'ACCOUNT') ? this.responsibles : this.measures
            } : undefined
          });
        }
      }
      responsiblePlans.forEach((responsiblePlan: ResponsiblePlan) => {
        this.responsiblePlans.push(new ResponsiblePlanViewModel(responsiblePlan));
      });
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => { subscription.unsubscribe(); });
  }

  public removeRow(event): void {
    const responsiblePlanId = event.data.id || event.data.responsiblePlan.id;
    this.persistenceService.addObjectForDelete(responsiblePlanId).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Objekt gelöscht', type: ToastType.INFO });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
  }

  public rowUpdated(event): void {
    const responsiblePlanId = event.data.id || event.data.responsiblePlan.id;
    const responsiblePlan: ResponsiblePlan = new ResponsiblePlan(event.data.responsiblePlan);
    const query = this.responsiblePlanService.prepareResponsiblePersistObject(responsiblePlan);
    const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.responsiblePlan].definitionVM.multilingualProperties;
    const listProperties = this.sharedDataService.definitionsVM[TypeKey.responsiblePlan].definitionVM.listProperties;
    const object = this.sharedDataService.createPersistObject(query, multilingualProperties, listProperties);
    this.persistenceService.addObjectForUpdate(responsiblePlanId, object).subscribe(() => {
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
  }

  public openResponsiblePlanDialog(): void {
    this.form.instance.resetValues();
    this.createPlanPopup.instance.show();
  }

  public getRsponsibleFullName(responsible): string {
    return responsible ? responsible?.lastname + ', ' + responsible?.firstname : '';
  }

  public createResponsiblePlan(event: Event): void {
    event.preventDefault();
    this.persistObject().subscribe((data: ResponsiblePlan) => {
      const responsiblePlan = new ResponsiblePlanViewModel(data);
      this.sharedDataService.updateGridData(responsiblePlan);
      this.dataService.appService.callNotification({ message: 'Erfolg', type: ToastType.SUCCESS });
      this.clearWindow();
    }, error => {
      this.dataService.appService.callNotification({ message: error, type: ToastType.ERROR });
    });
    this.responsiblePlan = new ResponsiblePlan(null);
    this.form.instance.resetValues();
  }

  private persistObject(): Observable<ResponsiblePlan> {
    const multilingualProperties = this.sharedDataService.definitionsVM[TypeKey.responsiblePlan].definitionVM.multilingualProperties;
    const listProperties = this.sharedDataService.definitionsVM[TypeKey.responsiblePlan].definitionVM.listProperties;
    const query = this.sharedDataService.createPersistObject(this.responsiblePlan, multilingualProperties, listProperties);
    return this.persistenceService.addObjectForInsert(TypeKey.responsiblePlan, query);
  }

  public clearWindow(): void {
    this.createPlanPopup.instance.hide();
    this.form.instance.resetValues();
  }
}


