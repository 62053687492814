import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { TypeKey } from '@wissenswerft/core/data';
import { NamedItemComponent } from '@wissenswerft/organizational-structure';
import { Subscription } from 'rxjs';
import { DataService } from '../../../shared/data.service';

@Component({
  selector: 'tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements AfterViewInit, OnDestroy {

  @ViewChild('namedItem', { static: false }) namedItem: NamedItemComponent;

  public typeKey: TypeKey = TypeKey.tag;

  private subscriptions : Subscription[] = [];

  constructor(private dataService: DataService) { }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.namedItem.showNotification.subscribe(message => {
      this.dataService.appService.callNotification(message);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }
  
}
